import React, { Component } from 'react';
// import DatePicker from "react-datepicker";//need to remove
import { NavLink } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import InputField from './../../elements/Input';
import RadioBox from './../../elements/Radiobox';
import Button from './../../elements/Button';
// import axios from 'axios';//need to remove
import ModalPopup from '../../elements/Popup';
import KycComment from './KycComment';
import KycAllComment from './KycAllComment';
import { toast } from 'react-toastify';
import { DealerService, InventoryService } from './../../../service';
// import MasterService from './../../../service/MasterService';//need to remove
import { BANK_ACCOUNT_NUMBER, BENIFICIERY_NAME, BRANCH_NAME,REACT_APP_TENANT } from './../../../config/constant';
import Q from 'q';
// import { Link } from 'react-router-dom';//need to remove
import { withTranslation } from 'react-i18next';
import Accordion from './../../elements/Accordion';
import secureStorage from './../../../config/encrypt';
import { connect } from 'react-redux';
import moment from 'moment';
import {PatternFormat} from 'react-number-format';
import ViewHistory from './ViewHistory';
import ToolTip from '../../elements/Tooltip';
import ShareDocHistory from './ShareDocHistory';
const REASON_FOR_RECOMMENDATION = [
  {
    id: 1,
    value: 'Survey Report Recommendation',
  },
  {
    id: 2,
    value: 'Sales User Recommendation',
  },
  {
    id: 3,
    value: 'Other',
  },
];
class KycDetails extends Component {
    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangetext = this.handleChangetext.bind(this);
        this.addNewKycDetail = this.addNewKycDetail.bind(this);
        this.deleteNewKycDetail = this.deleteNewKycDetail.bind(this);
        this.state = {
            key: 1,
            city_id: "",
            loading: true,
            bank_state_id: "",
            location: "",
            dealership: "",
            business: "",
            filteredcity: props.cityAllList || [],
            role_access: props.role_access || [],
            kyccategory: [],
            kycdoc: [],
            kycMasterlist: props.kycMasterlist || [],
            dealer_id_hash: "",
            startDate: new Date(),
            kycUploadFile: [],
            kycDealerData: {},
            kycDealerDetail: {},
            kycDealerDocData: [],
            kycDealerAddDoc: [{ file_url: "", kyc_category_id: 1 }],
            kycDealerCallVerificationData: [],
            basic_details: props.basic_details || {},
            kyc_category_id: '',
            errors: {},
            kyc_errors: [],
            kyc_doc_errors: [],
            kyc_child_doc_errors:[],
            kyc_upload_doc_errors: [],
            cv_link_errors: {},
            survey_report_address:{},
            survey_report_address_errors:{},
            survey_recommendation:{},
            survey_recommendation_errors:{},
            localityAllList: [],
            addNewBankDetail: false,
            bankList:[],
            survay_doc_errors: [], 
            survay_upload_doc_errors: [],
            suvay_report_comment:'',
            survay_image_remove:false,
            survay_report_detail:{},
            showCommentMsg:false,
            showAllCommentMsg:false,
            kycMasterlistAddKyc : [{
                id: 1,
                name: "Bank Statement",
                status: 1
            }, {
                id: 2,
                name: "Bank Cover",
                status: 1
            }],
            account_length:0,
            historyPopup : false,
            kycVerified: {},
            kycOriginalVerifiedStatus: {},
            shareDocPopup: false,
            kycDocsAvailable: [],
        }
    }
    componentDidMount = async () => {
        await this.getSurvayReportDetail();
        await this.getDealerKycDetail();
        await this.getDealerDocKycDetail();
        await this.getDealerCallVerificationKycDetail();
        await this.getBankList();
    }

    closeHistoryModel = () => {
        this.setState({ historyPopup: false });
    }

    handleHistory = () => {
        this.setState({historyPopup : true});
    }

    closeShareDocModel = () => {
        this.setState({ shareDocPopup: false });
    }

    getBankList = async()=>{
        DealerService.getMasters(["bank_list"]).then((response) => {
            if (response && response.status == 200 && response.data.status == 200) {
                this.setState({ bankList: response.data.data.bank_list});
            }
        }).catch((error) => {
            this.setState({loading:false})
        });
    }

    get get_dealer_id_hash() {
        return this.props.dealer_id_hash
    }
    handlebankListOptionChange = (ovalue, element) => {
        let errors = this.state.errors
        let kycMasterlist_new = { ...this.state.kycDealerData }
        kycMasterlist_new.bank_id = ovalue.bank_id;
        kycMasterlist_new.address1 = ""
        kycMasterlist_new.address2 = ""
        kycMasterlist_new.bank_account_no = ""
        kycMasterlist_new.bank_account_type = ""
        kycMasterlist_new.bank_city_id = ""
        kycMasterlist_new.bank_micr_code = ""
        kycMasterlist_new.bank_pincode = ""
        kycMasterlist_new.bank_state_id = ""
        kycMasterlist_new.beneficiary_name = ""
        kycMasterlist_new.branch_name = ""
        kycMasterlist_new.confirm_bank_account_no = ""

        if(element === "bankname" && ovalue){
            errors.bankname = ""
        }
        errors.address1 = ""
        // errors.bankname = ""
        errors.address2 = ""
        // errors.acno = ""
        errors.account_type = ""
        errors.city_id = ""
        errors.micrcode = ""
        errors.pincode = ""
        errors.state_id = ""
        // errors.beneficiary_name = ""
        // errors.branch_name = ""
        // errors.coacno = ""

        this.setState({ kycDealerData: kycMasterlist_new , account_length:ovalue.valid_length, errors:errors}, () => {
        })
    }
    handleStateOptionChange = () => (ovalue) => {
        // this.state.kycDealerData.bank_state_id = ovalue.id;//need to remove
        this.setState((prevState) => ({
            kycDealerData: {
              ...prevState.kycDealerData,
              bank_state_id: ovalue.id,
            }
          }));
        let citylist = [...this.state.cityAllList];
        // const result = citylist && citylist.filter(cityinfo => cityinfo.state_id == ovalue.id) || [];//need to remove
        const result = (citylist && citylist.filter(cityinfo => cityinfo.state_id == ovalue.id)) || [];
        this.setState({ filteredcity: result })
    }
    handleDocCategoryOptionChange = (idx) => (ovalue) => {
        let kycDealerDoc = [...this.state.kycDealerDocData];
        kycDealerDoc[idx].kyc_master_id = ovalue.id;
        this.setState({ kycDealerDocData: kycDealerDoc })
    }
    // handleChange = date => {//need to remove duplicate function
    //     this.setState({
    //         startDate: date
    //     });
    // };
    handleSelect(key) {
        this.setState({ key });
    }
    handledealertype = (sname, ovalue) => {
        let chkval = ovalue.target.value;
        let kycMasterlist_new = { ...this.state.kycDealerData }
        kycMasterlist_new.bank_account_type = chkval;
        this.setState({ kycDealerData: kycMasterlist_new }, () => {
        })
    }

    // handleChange = (sname, ovalue) => {//need to remove unused function
    //     this.setState({
    //         [sname]: ovalue.value
    //     });

    // }
    handletKycDealerDataTextChange = (sname, ovalue) => {
        let kycMasterlist_new = { ...this.state.kycDealerData }
        let errors = { ...this.state.errors }
        kycMasterlist_new[sname] = ovalue.target.value;

        if(sname === "branch_name")
        errors[sname] = ovalue.target.value ? "" : this.props.t('dealerDetails.kyc_details.validation_texts.branch_name_required');

        if(sname === "bank_account_no")
        errors['acno'] = ovalue.target.value ? "" : this.props.t('dealerDetails.kyc_details.validation_texts.account_number_required');

        if(sname === "confirm_bank_account_no")
        errors['coacno'] = ovalue.target.value ? "" : this.props.t('dealerDetails.kyc_details.validation_texts.confirm_account_number_required');

        if(sname === "beneficiary_name")
        errors[sname] = ovalue.target.value ? "" : this.props.t('dealerDetails.kyc_details.validation_texts.beneficiary_name_required');
        
        
        this.setState({ kycDealerData: kycMasterlist_new, errors:errors  }, () => {
        })
    }
    handletkycDealerDocTextChange = (sname, index, ovalue) => {    
        var kyc_errors = [...this.state.kyc_errors]; 
        kyc_errors[index] = {}
        let kycMasterlist_new = [...this.state.kycDealerDocData];
        kycMasterlist_new[index][sname] = ovalue.target.value;
        this.setState({ kycDealerDocData: kycMasterlist_new, kyc_errors:kyc_errors }, () => {

        })

    }
    handletkycDealerCallVerificationTextChange = (sname, index, ovalue) => {
        let kycMasterlist_new = [...this.state.kycDealerCallVerificationData];
        kycMasterlist_new[index][sname] = ovalue.target.value;
        this.setState({ kycDealerCallVerificationData: kycMasterlist_new }, () => {
        })
    }

    handleSurvayCommentChange = (sname, ovalue) => {
        this.setState({ suvay_report_comment : ovalue.target.value }, () => {
        })
    }

    handleKycCheckbox = (sname, index, dealerDocData, ovalue) => {
        if (dealerDocData && dealerDocData.kyc_master_id && [1, 3].includes(dealerDocData.kyc_master_id) && dealerDocData.is_doc_verified === 0) {
            if (!dealerDocData.doc_number) {
                toast.error('Document Number empty ')
                return;
            }
        }
        let tempKycMasterList = JSON.parse(JSON.stringify(this.state.kycDealerDocData));
        let tempKycVerified = JSON.parse(JSON.stringify(this.state.kycVerified))
        tempKycVerified[index] = ovalue.target.checked ? 1 : 0;
        tempKycMasterList[index][sname] = ovalue.target.checked ? 1 : 0
        this.setState({ kycDealerDocData: tempKycMasterList })
        this.setState({ kycVerified: tempKycVerified });
    }

    handleShareDoc = (event) => {
        event.preventDefault();
        this.setState({shareDocPopup: true});
    }

    handleCityOptionChange = (sname, ovalue) => {
        let kycMasterlist_new = { ...this.state.kycDealerData }
        kycMasterlist_new.bank_city_id = ovalue.id;
        this.setState({ kycDealerData: kycMasterlist_new }, () => {
        })

    }

    addNewKycDetail() {
        this.setState({ addNewBankDetail: true });
    }

    deleteNewKycDetail() {
        this.setState({ addNewBankDetail: false });
    }
    handleChangetext = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            basic_details: nextProps.basic_details,
            cityAllList: nextProps.cityAllList,
            filteredcity: nextProps.cityAllList,
            kycMasterlist: nextProps.kycMasterlist,
            role_access: nextProps.role_access || [],
        });
    }
    stringifyFormData(fd) {
        var object = {};
        fd.forEach((value, key) => {
            if (!object.hasOwnProperty(key)) {
                object[key] = value;
                return;
            }
            if (!Array.isArray(object[key])) {
                object[key] = [object[key]];
            }
            object[key].push(value);
        });
        return JSON.parse(JSON.stringify(object));
    }
    handleKycDocChange = (idx) => (ovalue) => {
        let kycDealerDocData_new = [...this.state.kycDealerDocData]
        let temp_kyc_child_doc_errors = [...this.state.kyc_child_doc_errors]
        delete temp_kyc_child_doc_errors[idx]?.kycdoc

        kycDealerDocData_new[idx].kyc_child_id = ovalue.kyc_child_id;
        kycDealerDocData_new[idx].doc_number='';
        this.setState({ kycDealerDocData: kycDealerDocData_new ,kyc_child_doc_errors:temp_kyc_child_doc_errors}, () => {
        })
    }

    getDealerKycDetail = async () => {
        DealerService.getKycDetails(this.get_dealer_id_hash).then(result => {
            if (result && result.data && result.data.status == 200) {
                let tempkycDealerData = [];
                let address1 = '';
                let address2 = '';
                if (result.data && result.data.data && result.data.data.length > 0) {
                    tempkycDealerData = result.data.data
                    for (let i = 0; i < tempkycDealerData.length; i++) {
                        if (tempkycDealerData.bank_address != '') {
                            address1 = tempkycDealerData[i].bank_address.split('|')[0];
                            address2 = tempkycDealerData[i].bank_address.split('|')[1];
                        }
                        tempkycDealerData[i].address1 = address1;
                        tempkycDealerData[i].address2 = address2;
                    }
                   
                }
                this.setState({ kycDealerData: tempkycDealerData, kycDealerDetail: tempkycDealerData , loading: false })
            }
            else {
                this.setState({ loading: false })
                toast.error(result.data.message || "Some Error Occurred");
            }
        }).catch(error => {
            this.setState({ loading: false })
            toast.error(error.message || "Some Error Occurred");
        });
    }
    getDealerDocKycDetail = async () => {
        DealerService.getDocKycDetails(this.get_dealer_id_hash).then(result => {
            if (result && result.data && result.data.status == 200) {
                this.setState({ kycDealerDocData: result.data.data }, () => { });
                let tempKycVerified = {};
                let tempKycOriginalStatus = {};
                let tempKycDocsAvailable = [];
                for (let i = 0; i < result.data.data.length; i++) {
                    if(result.data.data[i]?.kyc_doc_images?.length > 0){
                        tempKycVerified[i] = result.data.data[i].is_doc_verified ? 1 : 0
                    }
                    tempKycOriginalStatus[i] = result.data.data[i].is_doc_verified ? 1 : 0
                    if (result.data.data[i]?.kyc_doc_images?.length > 0 && result.data.data[i].is_doc_verified === 1) {
                        tempKycDocsAvailable.push(result.data.data[i].kyc_child_id);
                    }
                }
                this.setState({ kycVerified: tempKycVerified, kycOriginalVerifiedStatus: tempKycOriginalStatus, kycDocsAvailable: tempKycDocsAvailable });
            }
            else {
                toast.error(result.data.message || "Some Error Occurred");
            }
        }).catch(error => {
            toast.error(error.message || "Some Error Occurred");
        });
    }

    getDealerCallVerificationKycDetail = async () => {
        DealerService.kycDealerCallVerificationData(this.get_dealer_id_hash).then(result => {
            if (result && result.data && result.data.status == 200) {
                this.setState({ kycDealerCallVerificationData: result.data.data }, () => { });
            }
            else {
                toast.error(result.data.message || "Some Error Occurred");
            }
        }).catch(error => {
            toast.error(error.message || "Some Error Occurred");
        });
    }

    getSurvayReportDetail = async () => {
        DealerService.kycSurvayReportDetails(this.get_dealer_id_hash).then(result => {
            if (result && result.data && result.data.status == 200) {
                if(result?.data?.data && result?.data?.data?.length > 0){
                    this.setState(
                      {
                        suvay_report_comment: result.data.data[0].comment,
                        survay_report_detail: {
                          file_name: result.data.data[0].file_name,
                          file_url: result.data.data[0].file_url,
                          doc_type: result.data.data[0].doc_type,
                        },
                        survey_report_address: { ...result?.data?.data[0]?.survay_report_address_data },
                        survey_recommendation: { ...result?.data?.data[0]?.recommendation_status },
                      },
                      () => {}
                    );
                    this.getlocalityList(this.state.survey_report_address.survey_city_id);
                }
            }
            else {
                toast.error(result.data.message || "Some Error Occurred");
            }
        }).catch(error => {
            toast.error(error.message || "Some Error Occurred");
        });
    }

    
    s3UploadFiles = (allfiles, formPostdata) => {
        let dealerKycDocSaveArr = [];
        if (allfiles.length) {
            this.setState({ loading: true })
            let prom = [];
            allfiles.forEach((fileskyc, index) => {
                let filesData = fileskyc.files;
                var formData = new FormData();

                formData.append("upload_type", 'dealer_docs');
                formData.append("dealer_id", this.state.basic_details.id);
                if (filesData.length) {
                    formData.append('images', filesData[0]);
                    prom.push(InventoryService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' }))
                }
                else {
                    prom.push(Promise.resolve({ data: { status: 200, message: 'Success', data: ['not_updated'] } }));
                }
            });
            Q.allSettled(prom)
                .then((op) => {
                    op.forEach((res, index) => {
                        let response = res.value || [];
                        if (res.state == 'fulfilled') {
                            if (response.data.data.length) {
                                let tempFileName = '';
                                let tempFileUrl = '';
                                // let temp_kyc_master_id = 0;//need to remove
                                let dealerKycDocSaveJson = {};
                                if (formPostdata.kyccategory.length > 1) {
                                    dealerKycDocSaveJson = {
                                        "kyc_master_id": formPostdata.kyccategory[index],
                                        "kyc_child_id": formPostdata.kycdoc[index],
                                        "doc_number": (formPostdata.docnumber[index]) ? (formPostdata.docnumber[index]) : '',
                                        "comment": formPostdata.Comment[index] ? formPostdata.Comment[index] : '',
                                        'is_doc_verified': this.state.kycVerified[index] ? this.state.kycVerified[index] : 0
                                    }
                                    if (formPostdata.dc_dealer_kyc_doc[index] != null && formPostdata.dc_dealer_kyc_doc[index] > 0) {
                                        let dc_dealer_kyc_doc = formPostdata.dc_dealer_kyc_doc[index];
                                        dealerKycDocSaveJson.id = dc_dealer_kyc_doc;
                                    }
                                    if (formPostdata.saved_file_name[index] != null && formPostdata.saved_file_url[index]) {
                                        dealerKycDocSaveJson.file_name = formPostdata.saved_file_name[index];
                                        dealerKycDocSaveJson.file_url = formPostdata.saved_file_url[index];

                                    }
                                } else {
                                    dealerKycDocSaveJson = {
                                        "kyc_master_id": formPostdata.kyccategory,
                                        "kyc_child_id": formPostdata.kycdoc,
                                        "doc_number": (formPostdata.docnumber) ? formPostdata.docnumber : '',
                                        "comment": (formPostdata.Comment) ? formPostdata.Comment : '',
                                        'is_doc_verified': this.state.kycVerified[index] ? this.state.kycVerified[index] : 0
                                    }
                                    if (formPostdata.dc_dealer_kyc_doc != null && formPostdata.dc_dealer_kyc_doc > 0) {
                                        let dc_dealer_kyc_doc = formPostdata.dc_dealer_kyc_doc;
                                        dealerKycDocSaveJson.id = dc_dealer_kyc_doc;
                                    }
                                    if (formPostdata.saved_file_name != null && formPostdata.saved_file_url) {
                                        dealerKycDocSaveJson.file_name = formPostdata.saved_file_name;
                                        dealerKycDocSaveJson.file_url = formPostdata.saved_file_url;
                                    }
                                }
                                if (response.data.data[0] != 'not_updated') {
                                    tempFileName = response.data.data[0].file_name;
                                    tempFileUrl = response.data.data[0].file_url;
                                    dealerKycDocSaveJson["file_name"] = tempFileName;
                                    dealerKycDocSaveJson["file_url"] = tempFileUrl;
                                }

                                dealerKycDocSaveArr.push(dealerKycDocSaveJson);
                            }
                        }
                    });
                    let postData = {
                        dealer_id_hash: this.get_dealer_id_hash,
                        docs: dealerKycDocSaveArr
                    };
                    DealerService.saveKycDocDetails(postData).then(result => {
                        if (result && result.data && result.data.status == 200) {
                            this.setState({ loading: false })
                            toast.success(result.data.message, {
                                onClose: () => window.location.reload(true)
                            });

                        }
                        else {
                            this.setState({ loading: false })
                            toast.error(result.data.message || "Some Error Occurred");
                        }
                    }).catch(error => {
                        this.setState({ loading: false })
                        toast.error(error.message || "Some Error Occurred");
                    });
                })
                .catch((error) => {
                    this.setState({ loading: false })
                    toast.error(error.message || "Some Error Occurred");
                });
        }
    }
    s3UploadBankDetailFiles = (allfiles, formPostdata ,kyc_id) => {
        let dealerKycDocSaveArr = [];
        if (allfiles.length) {
            this.setState({ loading: true })
            let prom = [];
            allfiles.forEach((fileskyc, index) => {
                let filesData = fileskyc.files;
                var formData = new FormData();
                formData.append("upload_type", 'dealer_docs');
                formData.append("dealer_id", this.state.basic_details.id);
                if (filesData.length) {
                    formData.append('images', filesData[0]);
                    prom.push(InventoryService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' }))
                }
                else {
                    prom.push(Promise.resolve({ data: { status: 200, message: 'Success', data: ['not_updated'] } }));
                }
            });
            Q.allSettled(prom)
                .then((op) => {
                    op.forEach((res, index) => {
                        let response = res.value || [];
                        if (res.state == 'fulfilled') {
                            if (response.data.data.length) {
                                let tempFileName = '';
                                let tempFileUrl = '';
                                // let temp_kyc_master_id = 0;//need to remove
                                let dealerKycDocSaveJson = {};
                                if (formPostdata.add_kyc_category.length > 1) {
                                    if (formPostdata.saved_file_name[index] != null && formPostdata.saved_file_url[index]) {
                                        dealerKycDocSaveJson.file_name = formPostdata.saved_file_name[index];
                                        dealerKycDocSaveJson.file_url = formPostdata.saved_file_url[index];
                                        dealerKycDocSaveJson.kyc_id = kyc_id;
                                        dealerKycDocSaveJson.kyc_category_id = formPostdata.add_kyc_category[index];
                                    }
                                } else {
                                    if (formPostdata.saved_file_name != null && formPostdata.saved_file_url) {
                                        dealerKycDocSaveJson.file_name = formPostdata.saved_file_name;
                                        dealerKycDocSaveJson.file_url = formPostdata.saved_file_url;
                                        dealerKycDocSaveJson.kyc_id = kyc_id;
                                        dealerKycDocSaveJson.kyc_category_id = formPostdata.add_kyc_category[index];
                                    }
                                }
                                if (response.data.data[0] != 'not_updated') {
                                    tempFileName = response.data.data[0].file_name;
                                    tempFileUrl = response.data.data[0].file_url;
                                    dealerKycDocSaveJson["file_name"] = tempFileName;
                                    dealerKycDocSaveJson["file_url"] = tempFileUrl;
                                    dealerKycDocSaveJson.kyc_id = kyc_id;
                                    dealerKycDocSaveJson.kyc_category_id = formPostdata.add_kyc_category[index];

                                }
                                dealerKycDocSaveArr.push(dealerKycDocSaveJson);
                            }
                        }
                    });
                    let postData = {
                        dealer_id_hash: this.get_dealer_id_hash,
                        docs: dealerKycDocSaveArr
                    };
                    DealerService.saveKycBankDocDetails(postData).then(result => {
                        if (result && result.data && result.data.status == 200) {
                            this.setState({ addNewBankDetail: false, loading: false });
                            this.getDealerKycDetail();
                            toast.success(result.data.message);
                            // toast.success(result.data.message, {
                            //     onClose: () => window.location.reload(true)
                            // });

                        }
                        else {
                            this.setState({ loading: false })
                            toast.error(result.data.message || "Some Error Occurred");
                        }
                    }).catch(error => {
                        this.setState({ loading: false })
                        toast.error(error.message || "Some Error Occurred");
                    });
                })
                .catch((error) => {
                    this.setState({ loading: false })
                    toast.error(error.message || "Some Error Occurred");
                });
        }
    }
    saveBankDetail = async (event) => {
        event.preventDefault();
        // var parentThis = this;//need to remove
        const form = event.target;
        const data = new FormData(form);
        const formdata = this.stringifyFormData(data);
           if (this.validFormData(formdata)) {
            this.setState({ loading: true });
            var r = window.confirm(this.props.t('dealerDetails.kyc_details.warning_alert_msg'));
            if (r==true) {
                let allfiles = document.querySelectorAll('.add_bank_file input[type="file"]');
                let dealerKycSaveJson = {
                    "dealer_id_hash": this.get_dealer_id_hash,
                    "bank_id": formdata.bankname,
                    "bank_address": formdata.bankaddress1 + " | " + formdata.bankaddress2,
                    "bank_state_id": formdata.state_id || 0,
                    "bank_city_id": formdata.city_id || 0,
                    "bank_pincode": formdata.pincode || 0,
                    "bank_account_type": formdata.account_type,
                    "bank_account_no": formdata.acno,
                    "confirm_bank_account_no": formdata.acno,
                    "branch_name": formdata.branch_name,
                    "beneficiary_name": formdata.beneficiary_name,
                    "bank_micr_code": formdata.micrcode
                    // "bank_ifsc_code": formdata.ifsccode
                };
                DealerService.saveKycDetails(dealerKycSaveJson).then(result => {
                    if (result && result.data && result.data.status == 200) {
                        this.s3UploadBankDetailFiles(allfiles, formdata, result.data.data.id);
                        this.setState({ kycDealerAddDoc: [{ file_url: "", kyc_category_id: 1 }] })
                        // this.getDealerKycDetail();
                        // toast.success(result.data.message);
                    }
                    else {
                        this.setState({ loading: false })
                        toast.error(result.data.message || "Some Error Occurred");
                    }
                }).catch(error => {
                    this.setState({ loading: false })
                    toast.error(error.message || "Some Error Occurred");
                });
            } else {
                this.setState({ loading: false })
            }
         }
    }
    saveKycDetail = (event) => {
        event.preventDefault();
        // var parentThis = this;//need to remove
        const form = event.target;
        const data = new FormData(form);
        const formdata = this.stringifyFormData(data);
        const docVerificationStatus = this.state.kycVerified;
        var formIsValid = false;
        var kyc_errors = [...this.state.kyc_errors];
        var kyc_child_doc_errors = [...this.state.kyc_child_doc_errors];
        var error = {};
        //console.log('########################',formdata);
        Object.keys(formdata).forEach((key) => {
            if (key == 'kyccategory' || key == 'kycdoc' || key=='docnumber') {
                let key_error_msg = '';
                if (key == 'kyccategory') {
                    key_error_msg = 'key category';
                } else if (key == 'kycdoc') {
                    key_error_msg = 'key doc';
                }else if(key=='docnumber'){
                    key_error_msg = 'key doc number';
                }
                if (Array.isArray(formdata[key]) && formdata[key].length > 0) {
                    formdata[key].forEach((fileskyc, index) => {
                        let value = formdata[key][index];
                        let key_doc_value=formdata['kycdoc'][index];                        
                        let key_doc_master_value=formdata['kyccategory'][index];                        
                        let req_doc_master = [1, 3]
                        if(key=='docnumber'){ 
                            let tempError={};   
                            kyc_errors[index] = tempError;                        
                            if(value !='' && key_doc_value==2){
                                // eslint-disable-next-line no-useless-escape
                                if(!value.match(/^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\.[0-9]\-[0-9]{3}\.[0-9]{3}$/)){
                                    error[key] = this.props.t('dealerDetails.kyc_details.validation_texts.valid_npwp');
                                    tempError['docnumber'] = this.props.t('dealerDetails.kyc_details.validation_texts.valid_npwp');
                                    kyc_errors[index] = tempError;
                                }                 
                            }

                            if(value == '' && req_doc_master.includes(parseInt(key_doc_master_value))){
                                error[key] = this.props.t('dealerDetails.kyc_details.validation_texts.doc_no_req');
                                tempError['docnumber'] = this.props.t('dealerDetails.kyc_details.validation_texts.doc_no_req');
                                kyc_errors[index] = tempError;
                            }

                        }else if (!value) {
                            error[key] = this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg;
                            kyc_errors[index] = error;
                        }
                        if (key == 'kycdoc') {
                            if (!key_doc_value) {
                              let tempError = {};
                              kyc_child_doc_errors[index] = tempError;
                              error[key] = this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg;
                              tempError['kycdoc'] = this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg;
                              kyc_child_doc_errors[index] = tempError;
                            }
                        }
                    })
                } else {
                    let value = formdata[key];
                    if (!value) {
                        error[key] = this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg;
                        kyc_errors[0] = error;
                    }
                }
            }

        })        
        if(Object.keys(docVerificationStatus).length){
            for (const [key, value] of Object.entries(docVerificationStatus)) {
                if (value === 0) {
                    error['is_doc_verified'] = "Unverified Document";
                    kyc_errors[key] = error;
                }
            }
        }
       
        this.setState({ kyc_errors: kyc_errors,kyc_child_doc_errors }, () => { })
        if ((Object.keys(error).length) == 0) {
            formIsValid = true;
        }
        if (formIsValid) {
            // let allfiles = document.querySelectorAll('input[type="file"]');
            let allfiles = document.querySelectorAll('input[type="file"].dealer-doc');
            this.s3UploadFiles(allfiles, formdata);
        } else {
            toast.error(this.props.t('dealerDetails.kyc_details.validation_texts.Please_resolve_error'));
        }

    }
    saveKycCallVerificationDetail = (event) => {
        event.preventDefault();
        // var parentThis = this;//need to remove
        let dealerKycCallVerificationSaveArr = [];
        const form = event.target;
        const data = new FormData(form);
        const formdata = this.stringifyFormData(data);
        var formIsValid = false;
        var error = {}; 
        if ((Object.keys(error).length) == 0) {
            formIsValid = true;
        }
        let dealerKycDocSaveJson = {};
        if (formIsValid) {
            if (Array.isArray(formdata.comment)) {
                for (let index = 0; index < formdata.comment.length; index++) {
                    dealerKycDocSaveJson = {
                        "comment": formdata.comment[index],
                        "link": formdata.link[index]
                    }
                    if (formdata.dc_dealer_kyc_call_verification_id[index] != null && formdata.dc_dealer_kyc_call_verification_id[index] > 0) {
                        let id = formdata.dc_dealer_kyc_call_verification_id[index];
                        dealerKycDocSaveJson.id = id;
                    }
                    // if (!dealerKycDocSaveJson.link.trim()) error[index + "_link"] = "required";
                    dealerKycCallVerificationSaveArr.push(dealerKycDocSaveJson);
                }
            } else {     
                dealerKycDocSaveJson = {
                    "comment": formdata.comment,
                    "link": formdata.link
                }            
                if (formdata.dc_dealer_kyc_call_verification_id!='') {
                    let id = formdata.dc_dealer_kyc_call_verification_id;
                    dealerKycDocSaveJson.id = id;
                }
                // if (!dealerKycDocSaveJson.link.trim()) error["0_link"] = "required";
                dealerKycCallVerificationSaveArr.push(dealerKycDocSaveJson);
            }
            let postData = {
                dealer_id_hash:this.get_dealer_id_hash,
                docs: dealerKycCallVerificationSaveArr
            };
            // if(Object.keys(error).length > 0) {
            //     this.setState({ cv_link_errors: error }, () => { });
            //     toast.error(this.props.t('dealerDetails.kyc_details.validation_texts.Please_resolve_error'));
            //     return;
            // }
            // this.setState({ cv_link_errors: error }, () => { });

            DealerService.saveKycCallVerificationDetails(postData).then(result => {
                if (result && result.data && result.data.status == 200) {
                    this.setState({ loading: false })
                    toast.success(result.data.message, {
                        onClose: () => window.location.reload(true)
                    });
                }
                else {
                    this.setState({ loading: false })
                    toast.error(result.data.message || "Some Error Occurred");
                }
            }).catch(error => {
                this.setState({ loading: false })
                toast.error(error.message || "Some Error Occurred");
            });
        } else {
            toast.error(this.props.t('dealerDetails.kyc_details.validation_texts.Please_resolve_error'));
        }
    }

    deleteKycDocRow = (idx) => {
        let dealerKycDocRow = [...this.state.kycDealerDocData];
        dealerKycDocRow.splice(idx, 1);
        this.setState({ kycDealerDocData: dealerKycDocRow });
    }
    deleteKycDocRowCategory = (idx) => {
        let dealerKycDocRowData = [...this.state.kycDealerAddDoc];
        let kyc_doc_errors_msg = [...this.state.kyc_doc_errors];
        let kyc_upload_doc_errors_msg = [...this.state.kyc_upload_doc_errors];
        dealerKycDocRowData.splice(idx, 1);
        kyc_doc_errors_msg.splice(idx, 1);
        kyc_upload_doc_errors_msg.splice(idx, 1);
        this.setState({ kycDealerAddDoc: dealerKycDocRowData ,kyc_doc_errors: kyc_doc_errors_msg ,kyc_upload_doc_errors: kyc_upload_doc_errors_msg});
    }
    deleteKycCallVerificationRow = (idx) => {
        let dealerKycDocRow = [...this.state.kycDealerCallVerificationData];
        dealerKycDocRow.splice(idx, 1);
        this.setState({ kycDealerCallVerificationData: dealerKycDocRow });
    }
    removecurrentdoc = (idx,doc,catgory_id) => {
        // let kycDealerDocData_new = [...this.state.kycDealerDocData]
        // let tempKycVerified = JSON.parse(JSON.stringify(this.state.kycVerified));
        // delete tempKycVerified[idx];
        // kycDealerDocData_new[idx].file_url = null;
        // this.setState({ kycDealerDocData: kycDealerDocData_new, kycVerified: tempKycVerified }, () => {
        // })
        var confirm = window.confirm(this.props.t('dealerDetails.kyc_details.remove_doc'));
        if(doc && doc.id > 0 && confirm==true){
            this.setState({ loading: true })
            DealerService.removekycdoc({id:doc.id,category_id:catgory_id}).then(result => {
                if (result && result.data && result.data.status == 200) {
                    this.setState({ loading: false })
                    toast.success(result.data.message, {
                        onClose: () => window.location.reload(true)
                    });
                }
                else {
                    this.setState({ loading: false })
                    toast.error(result.data.message || "Some Error Occurred");
                }
            }).catch(error => {
                this.setState({ loading: false })
                toast.error(error.message || "Some Error Occurred");
            });
        }

    }

    removecurrentsurvaydoc = (idx) => {
        this.setState({ survay_report_detail: {},survay_image_remove:true }, () => {
    })
    }
    addKycRow = () => {
        let dealerKycDocRow = [...this.state.kycDealerDocData];
        let newDealerKycPush = { 'file_url': '' };
        dealerKycDocRow.push(newDealerKycPush);
        this.setState({ kycDealerDocData: dealerKycDocRow })
    }
    addKycRowCategory = () => {
        let dealerKycDocRow = [...this.state.kycDealerAddDoc];
        let newDealerKycPush = { 'file_url': '' };
        dealerKycDocRow.push(newDealerKycPush);
        this.setState({ kycDealerAddDoc: dealerKycDocRow })
    }

    handleKycDocCategoryOptionChange = (idx) => (ovalue) => {
        let kycDealerDoc = [...this.state.kycDealerAddDoc];
        kycDealerDoc[idx].kyc_category_id = ovalue.id;
        this.setState({ kycDealerAddDoc: kycDealerDoc })
    }
    addKycCallVerificationRow = () => {
        let dealerKycDocRow = [...this.state.kycDealerCallVerificationData];
        let newDealerKycPush = {};
        dealerKycDocRow.push(newDealerKycPush);
        this.setState({ kycDealerCallVerificationData: dealerKycDocRow })
    }
    validFormData = (formData) => {
        let errors = {};
        let formIsValid = true;
        var kyc_doc_errors = [...this.state.kyc_doc_errors];
        var kyc_upload_doc_errors = this.state.kyc_upload_doc_errors;

        if (!formData['bankname']) {
            formIsValid = false;
            errors["bankname"] = this.props.t('dealerDetails.kyc_details.validation_texts.bank_name_required');
        }

        if (!formData['branch_name']) {
            formIsValid = false;
            errors["branch_name"] = this.props.t('dealerDetails.kyc_details.validation_texts.branch_name_required');
        }else if (!BRANCH_NAME.test(formData['branch_name'])) {
            formIsValid = false;
            errors["branch_name"] = this.props.t('dealerDetails.kyc_details.validation_texts.branch_name_not_valid');
        }

        if (!formData['beneficiary_name']) {
            formIsValid = false;
            errors["beneficiary_name"] = this.props.t('dealerDetails.kyc_details.validation_texts.beneficiary_name_required');
        }
        else if (!BENIFICIERY_NAME.test(formData['beneficiary_name'])) {
            formIsValid = false;
            errors["beneficiary_name"] = this.props.t('dealerDetails.kyc_details.validation_texts.beneficiary_name_not_valid');
        }
        let bankListTemp = this.state.bankList
        var bank_list_selected = bankListTemp.filter(elm => parseInt(elm.bank_id) === parseInt(formData.bankname));
        let account_length = bank_list_selected && bank_list_selected.length ? bank_list_selected[0].valid_length : 0
        if (!formData['acno']) {
            formIsValid = false;
            errors["acno"] = this.props.t('dealerDetails.kyc_details.validation_texts.account_number_required');
        }
        else if (!BANK_ACCOUNT_NUMBER.test(formData['acno'])) {
            formIsValid = false;
            errors["acno"] = this.props.t('dealerDetails.kyc_details.validation_texts.account_number_not_valid');
        } else if (account_length > 0 && formData['acno'].length !== account_length) {
            formIsValid = false;
            errors["acno"] = 'Format of Account Number must '+account_length+' digits';
        }

        if (!formData['coacno']) {
            formIsValid = false;
            errors["coacno"] = this.props.t('dealerDetails.kyc_details.validation_texts.confirm_account_number_required');
        } else if (!BANK_ACCOUNT_NUMBER.test(formData['coacno'])) {
            formIsValid = false;
            errors["coacno"] = this.props.t('dealerDetails.kyc_details.validation_texts.confirm_account_number_not_valid');
        } else if (formData['coacno'].length !== account_length && account_length > 0) {
            formIsValid = false;
            errors["coacno"] = 'Format of Account Number must '+account_length+' digits';
        } else if (!(formData['acno'].trim() === "" && formData['coacno'].trim() === "") && formData['coacno'] !== formData['acno']) {
            formIsValid = false;
            errors["coacno"] = this.props.t('dealerDetails.kyc_details.validation_texts.number_not_matching');
        }

        let allfiles = document.querySelectorAll('.add_bank_file input[type="file"]');
        Object.keys(formData).forEach((key) => {
            if (key == 'add_kyc_category') {
                let key_error_msg = '';
                let key_error_msg_new = 'Please upload DocFile';
                if (key == 'add_kyc_category') {
                    key_error_msg = 'kyc category';
                }
                if (Array.isArray(formData[key]) && formData[key].length > 0) {
                    formData[key].forEach((fileskyc, index) => {
                        let value = formData[key][index];
                        if (!value) {
                            errors[key] = this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg;
                            kyc_doc_errors[index] = errors;
                        }else{
                            delete kyc_doc_errors[index];
                        }
                        if (allfiles[index].files.length == 0) {
                            kyc_upload_doc_errors[index] = { 'doc_upload': this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg_new };
                        } else {
                            delete kyc_upload_doc_errors[index];
                        }
                    })
                }
                else {
                    kyc_upload_doc_errors[0] = { 'doc_upload': this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg_new };;
                }
            }

        })
        if(allfiles[0].files.length>0){
            delete kyc_upload_doc_errors[0];
        }
        this.setState({ kyc_doc_errors: kyc_doc_errors }, () => { })
        if ((Object.keys(errors).length) == 0 && (Object.keys(kyc_upload_doc_errors).length) == 0) {
            formIsValid = true;
            this.setState({ kyc_doc_errors: [], kyc_upload_doc_errors: [] })
        } else {
            formIsValid = false;
        }
        this.setState({ errors: errors })
        return formIsValid;
    }

    handleChangeForFlip = (type, index, kycId) => async ({ target }) => {
        let kycDealerDetail = [...this.state.kycDealerDetail];
        let status = ''
        if (target.checked) {
           kycDealerDetail[index]['active_status'] = '1';
           kycDealerDetail[index]['id'] = kycId;
            status = '1';
        } else {
            kycDealerDetail[index]['active_status'] = '0';
            kycDealerDetail[index]['id'] = kycId;
            status = '0';
        }
        this.setState({ kycDealerDetail });
        this.setState({ [type]: true, kycId: kycId, currentIndex: index, active_status: status });
    }

  

    handleChangeForViewComment = (type, index, kyc_comment_data) => {
        let kycDealerDetail = [...this.state.kycDealerDetail];
        this.setState({ kycDealerDetail });
        this.setState({ [type]: true, currentIndex: index, comment: kyc_comment_data });
    }
    
    closeModal = (flag=true) => {
        if (flag !== false) {
            let index = this.state.currentIndex;
            let kycDealerDetail = [...this.state.kycDealerDetail];
            if (kycDealerDetail[index]['active_status'] == '1') {
                kycDealerDetail[index]['active_status'] = '0';
            } else {
                kycDealerDetail[index]['active_status'] = '1';
            }
            this.setState({ kycDealerDetail });
        }
        this.setState({ showCommentMsg: false ,showAllCommentMsg: false});
    }

    closeModalViewAllComment = () => {
        this.setState({ showCommentMsg: false ,showAllCommentMsg: false});
    }
    

    onChangeHandler = event => {
        let files = event.target.files
        let err = [] // create empty array
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'application/pdf','application/wps-office.pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
        for (let x = 0; x < files.length; x++) {
            if (types.every(type => files[x].type !== type)) {
                err[x] = files[x].type + ' ' + this.props.t('dealerDetails.kyc_details.kyc_file_support_msg') + ' \n';
                // assign message to array
            }
            if(files[x].size && files[x].size > 8000000){
                err[x] = this.props.t('dealerDetails.kyc_details.kyc_file_size_error') + ' \n';
            }
        };
        for (var z = 0; z < err.length; z++) { // loop create toast massage
            event.target.value = null
            toast.error(err[z])
        }
        let allfiles = document.querySelectorAll('.add_bank_file input[type="file"]');
        if (allfiles && allfiles.length > 0 && allfiles[0].files.length > 0) {
            this.setState({ kyc_doc_errors: [], kyc_upload_doc_errors: [] })
        } 
        
        return true;
    }
    saveSurvayReportDetail = (event) => {
        event.preventDefault();
        let dealerSurvaySaveDetail = [];
        let allfiles = document.querySelectorAll('.survay_report_file input[type="file"]');
        let isUpload = allfiles && allfiles.length > 0 && allfiles[0].files.length > 0 ? true : false
        if (this.validSurveyFormData()) {
        if (allfiles && allfiles.length > 0 && allfiles[0].files.length > 0) {
              this.setState({ loading: true });
              let prom = [];
              allfiles.forEach((fileskyc, index) => {
                let filesData = fileskyc.files;
                var formData = new FormData();
          
                formData.append('upload_type', 'dealer_docs');
                formData.append('dealer_id', this.state.basic_details.id);
                if (filesData.length) {
                  formData.append('images', filesData[0]);
                  prom.push(InventoryService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' }));
                } else {
                  prom.push(Promise.resolve({ data: { status: 200, message: 'Success', data: ['not_updated'] } }));
                }
              });
              Q.allSettled(prom)
                .then((op) => {
                  op.forEach((res, index) => {
                    let response = res.value || [];
                    if (res.state == 'fulfilled') {
                      if (response.data.data.length) {
                        let tempFileName = '';
                        let tempFileUrl = '';
                        let dealerKycDocSaveJson = {};
                        if (response.data.data[0] != 'not_updated') {
                          tempFileName = response.data.data[0].file_name;
                          tempFileUrl = response.data.data[0].file_url;
                          dealerKycDocSaveJson['file_name'] = tempFileName;
                          dealerKycDocSaveJson['file_url'] = tempFileUrl;
                        }
                        dealerSurvaySaveDetail.push(dealerKycDocSaveJson);
                      }
                    }
                  });
                  let postData = {
                    dealer_id_hash: this.get_dealer_id_hash,
                    docs: dealerSurvaySaveDetail,
                    suvay_report_comment: this.state.suvay_report_comment,
                    suvay_report_address_data: this.state.survey_report_address,
                    recommendation_status: this.state.survey_recommendation,
                  };
                  DealerService.saveSurvayReportsDetails(postData)
                    .then((result) => {
                      if (result && result.data && result.data.status == 200) {
                        this.setState({ loading: false });
                        toast.success(result.data.message, {
                          onClose: () => window.location.reload(true),
                        });
                      } else {
                        this.setState({ loading: false });
                        toast.error(result.data.message || 'Some Error Occurred');
                      }
                    })
                    .catch((error) => {
                      this.setState({ loading: false });
                      toast.error(error.message || 'Some Error Occurred');
                    });
                })
                .catch((error) => {
                  this.setState({ loading: false });
                  toast.error(error.message || 'Some Error Occurred');
                });
            } else {
              this.setState({ loading: true });
              let obj = {
                dealer_id_hash: this.get_dealer_id_hash,
                docs: [],
                survay_image_remove:this.state.survay_image_remove,
                suvay_report_comment: this.state.suvay_report_comment,
                suvay_report_address_data: this.state.survey_report_address,
                recommendation_status: this.state.survey_recommendation,
              };
              DealerService.saveSurvayReportsDetails(obj)
                .then((result) => {
                  if (result && result.data && result.data.status == 200) {
                    this.setState({ loading: false });
                    toast.success(result.data.message, {
                      onClose: () => window.location.reload(true),
                    });
                  } else {
                    this.setState({ loading: false });
                    toast.error(result.data.message || 'Some Error Occurred');
                  }
                })
                .catch((error) => {
                  this.setState({ loading: false });
                  toast.error(error.message || 'Some Error Occurred');
                });
            }
        }
      };

    validSurveyFormData = () => {
        let errors = {};
        let formIsValid = true;
        var formData = {...this.state.survey_report_address};
        const recommendationData = {...this.state.survey_recommendation};
        const survay_report_detail = {...this.state.survay_report_detail};


        if (!formData['survey_address_line1']) {
            formIsValid = false;
            errors["survey_address_line1"] = this.props.t('dealerDetails.kyc_details.validation_texts.survey_address_line1_required');
        }

        if (!formData['survey_state_id']) {
            formIsValid = false;
            errors["survey_state_id"] = this.props.t('dealerDetails.kyc_details.validation_texts.survey_state_id_required');
        }

        if (!formData['survey_city_id']) {
            formIsValid = false;
            errors["survey_city_id"] = this.props.t('dealerDetails.kyc_details.validation_texts.survey_city_id_required');
        }

        if (!formData['survey_district_id']) {
            formIsValid = false;
            errors["survey_district_id"] = this.props.t('dealerDetails.kyc_details.validation_texts.survey_district_id_required');
        }

        if (!formData['survey_locality_id']) {
            formIsValid = false;
            errors["survey_locality_id"] = this.props.t('dealerDetails.kyc_details.validation_texts.survey_locality_id_required');
        }

        if (recommendationData['is_recommended'] == '1') {
          if (!recommendationData['reason_for_recommendation']) {
            formIsValid = false;
            errors['reason_for_recommendation'] = this.props.t('dealerDetails.kyc_details.validation_texts.reason_for_recommendation_required');;
          }

          let allfiles = document.querySelectorAll('.survay_report_file input[type="file"]');
          let isUpload = allfiles && allfiles.length > 0 && allfiles[0].files.length > 0 ? true : false;

          if (recommendationData['reason_for_recommendation'] == 1 && (!survay_report_detail.file_url && !isUpload)) {
            formIsValid = false;
            errors['survey_doc'] = this.props.t('dealerDetails.kyc_details.validation_texts.recommendation_survey_doc_required');
          }

          if (recommendationData['reason_for_recommendation'] == 2 && !recommendationData['sfa_user_id']) {
            formIsValid = false;
            errors['sfa_user_id'] = this.props.t('dealerDetails.kyc_details.validation_texts.recommendation_sfa_id_required');
          }

          if (recommendationData['reason_for_recommendation'] == 3 && !recommendationData['comment']) {
            formIsValid = false;
            errors['recommendation_comment'] = this.props.t('dealerDetails.kyc_details.validation_texts.recommendation_comment_required');
          }
        }
       
        this.setState({ survey_report_address_errors: errors ,survey_recommendation_errors:errors})
        return formIsValid;
    }
    handleSurveyOptionChange = (name,ovalue) => {
        let tempobj = {...this.state.survey_report_address}
        let errors = {...this.state.survey_report_address_errors}
        let id = ovalue?.id ? ovalue.id : 0
        if(name == 'survey_city_id'){
            tempobj['survey_district_id'] = 0;
            tempobj['survey_locality_id'] = 0;
            this.getlocalityList(id);
        }
        if(name == 'survey_state_id'){
            tempobj['survey_city_id'] = 0;
            tempobj['survey_district_id'] = 0;
            tempobj['survey_locality_id'] = 0;
        }
        if(['survey_address_line1','survey_address_line2'].includes(name)){
            tempobj[name] = ovalue.target.value;
        }else{
            tempobj[name] = id;
        }
        delete errors[name]
        this.setState({ survey_report_address: tempobj,survey_report_address_errors:errors})
    }

    handleRecommendationStatus = (name,ovalue) => {
        let tempobj = {...this.state.survey_recommendation}
        let errors = {...this.state.survey_recommendation_errors}
        let id = ovalue?.id ? ovalue.id : 0
        tempobj[name] = id
        delete errors[name]
        this.setState({ survey_recommendation: tempobj,survey_recommendation_errors:errors})
    }

    handleRecommendationType = (sname, ovalue) => {
        let chkval = ovalue.target.value;
        let survey_obj = { ...this.state.survey_recommendation }
        survey_obj.is_recommended = chkval;
        this.setState({ survey_recommendation: survey_obj }, () => {
        })
    }

    handleRecommendationTextChange = (sname, ovalue) => {
        let temp_obj = { ...this.state.survey_recommendation }
        let errors = { ...this.state.survey_recommendation_errors }
        if (sname == 'sfa_user_id') {
          const validated = ovalue.target.value.match(/^[0-9]+$/);
          if (validated || ovalue.target.value == '') {
            temp_obj[sname] = ovalue.target.value;
          }
        }else{
            temp_obj[sname] = ovalue.target.value;
        }
        let errorname = sname
        if(sname == 'comment'){
            errorname = 'recommendation_comment'
        }
        delete errors[errorname]
        this.setState({ survey_recommendation: temp_obj, survey_recommendation_errors:errors  }, () => {
        })
    }

    getlocalityList(city_id) {
        if(city_id){
            InventoryService.localityList({city:[city_id]}).then(response => {
                    if (response.data.status === 200 && response.status === 200) {
                         this.setState({localityAllList:response.data.data})
                    }
                }).catch(error => {
                    toast.error(error.message);
                }); 
        }
    }

    getSurveyAddressHeading(survey_data) {
        if(survey_data?.address_type == 'home'){
            return this.props.t('dealerDetails.Outlet_Details_master.home_address')
        }else if(survey_data?.address_type == 'outlet'){
            return this.props.t('dealerDetails.Outlet_Details_master.Outlet_address')
        }else if(survey_data?.address_type == 'current'){
            return this.props.t('dealerDetails.Outlet_Details_master.current_address')
        }
    }
    survayReportFileHandler = event => {
        let files = event.target.files
        let errors = { ...this.state.survey_recommendation_errors }
        let err = [] // create empty array
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'application/pdf','application/wps-office.pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
        for (let x = 0; x < files.length; x++) {
            if (types.every(type => files[x].type !== type)) {
                err[x] = files[x].type + ' ' + this.props.t('dealerDetails.kyc_details.kyc_file_support_msg') + ' \n';
                // assign message to array
            }
            if(files[x].size && files[x].size > 8000000){
                err[x] = this.props.t('dealerDetails.kyc_details.kyc_file_size_error') + ' \n';
            }
        };
        for (var z = 0; z < err.length; z++) { // loop create toast massage
            event.target.value = null
            toast.error(err[z])
        }
        let allfiles = document.querySelectorAll('.survay_report_file input[type="file"]');
        if (allfiles[0].files.length > 0) {
          delete errors['survey_doc'];
          this.setState({ survay_doc_errors: [], survay_upload_doc_errors: [], survey_recommendation_errors: errors });
        }
        return true;
    }
    render() {
        const bankList = this.state.bankList;
        const { StateList, cityAllList,districtAllList} = this.props;
        const { kycMasterlistAddKyc ,kycDealerAddDoc, addNewBankDetail, filteredcity, kycMasterlist, kycDealerData, kycDealerDetail, kycDealerDocData, kycDealerCallVerificationData, errors, kyc_errors ,kyc_upload_doc_errors,kyc_doc_errors,kyc_child_doc_errors} = this.state;
        const { cv_link_errors ,role_access,suvay_report_comment,survay_report_detail,survey_report_address,localityAllList ,survey_report_address_errors,survey_recommendation,survey_recommendation_errors} = this.state;
        let basicinfo = this.state.basic_details;
        let masterlist = {};
        if (kycMasterlist) {
            kycMasterlist.forEach((val) => {
                if(val.id == 4){
                    if(basicinfo.premium_code == 'hd'){//home dealer
                        masterlist['cat_' + val.id] = val?.child?.filter(ele => ([6,9,10,11,12,14,18].includes(ele.kyc_child_id)));
                    }else if(basicinfo.premium_code == 'bkd'){//broker dealer
                        masterlist['cat_' + val.id] = val?.child?.filter(ele => ([6,9,10,11,12,18].includes(ele.kyc_child_id)));
                    }else{//regular dealer
                        masterlist['cat_' + val.id] = val?.child?.filter(ele => ([13,10,12,6,9,18].includes(ele.kyc_child_id)));
                    }
                }else{
                    masterlist['cat_' + val.id] = val?.child;
                }
            })
        }
        let role;
       // console.log('#############################',kyc_errors);
        if (secureStorage.getItem('loginUserInfo')) {
            let loginResponse = secureStorage.getItem('loginUserInfo');
            if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
                role = loginResponse.data.user_data.role;
            }
        }
        return (
            <div className={(this.state.loading ? "card loading" : "card")}>
                <div className="card-heading">
                    <div className="pull-left">
                        <h1 className="card-title">
                            {this.props.t('dealerDetails.kyc_details.kyc_details_label')} <span className="f12">({basicinfo.organization} - {basicinfo.gcd_code})</span>
                            <span className="f12">
                        {
                            (basicinfo.vendor_id) ? <span> Vendor ID - {basicinfo.vendor_id}</span> : ''
                        }
                    </span>
                        </h1>
                    </div>
                    <div className="pull-right">
                        <button className="btn" onClick={() => {this.handleHistory()}}>{this.props.t('dealerDetails.Basic_Details_master.view_history')}</button>
                        {
                            role_access.includes("kyc_add_bank") ? <button className="btn btn-success" onClick={this.addNewKycDetail}>{this.props.t('dealerDetails.kyc_details.add_more_bank_detail')}</button> : ''
                        }
                    </div>
                    <div className="clearfix"></div>
                </div>
                <div className="card-body">    
                    <p className="text-danger form-group f12">*{this.props.t('dealerDetails.kyc_details.kyc_bank_details_validations')}</p>
                    <div className="card os-animation animated fadeInDown" data-os-animation="fadeInDown" data-os-animation-delay="0s">
                        <div className="accrdian-bt0">
                            {addNewBankDetail ?
                                <Accordion title={this.props.t('dealerDetails.kyc_details.add_more_bank_detail')} active="true" delete="true">
                                    <button className="btn btn-link delete-user" onClick={() => { if (window.confirm(this.props.t('dealerDetails.Basic_Employee_Details.Confirm_Delete'))) this.deleteNewKycDetail() }} ><i className="ic-delete"></i>{this.props.t('dealerDetails.Basic_Employee_Details.Delete')}</button>

                                    <div className="card-heading">
                                        <h2 className="card-title">{this.props.t('dealerDetails.kyc_details.bank_detail')}</h2>
                                    </div>
                                    <div className="card-body">
                                        <form onSubmit={this.saveBankDetail} method="post">
                                            <div className="row">
                                                <div className="col-sm-4 form-group">
                                                    <label>{this.props.t('dealerDetails.kyc_details.bank_name')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        components={{ IndicatorSeparator:() => null }}
                                                        id="bankname"
                                                        onChange={(selectedOption) => this.handlebankListOptionChange(selectedOption, "bankname")}
                                                        options={bankList}
                                                        name="bankname"
                                                        placeholder={this.props.t('dealerDetails.kyc_details.bank_name')}
                                                        value={bankList.filter(({ bank_id }) => bank_id == kycDealerData.bank_id)}
                                                        getOptionLabel={({ name }) => name}
                                                        getOptionValue={({ bank_id }) => bank_id}
                                                    />
                                                    {errors.bankname &&
                                                        <div className="error show" >{errors.bankname}</div>
                                                    }
                                                </div>
                                                <div className="col-sm-4">
                                                    <InputField type="text" error={(errors.branch_name) ? errors.branch_name : ''} name="branch_name" id="branch_name" placeholder={this.props.t('dealerDetails.kyc_details.branch_name')} value={kycDealerData.branch_name} label={this.props.t('dealerDetails.kyc_details.branch_name')} onChange={this.handletKycDealerDataTextChange.bind(this, 'branch_name')} required={true} />
                                                </div>
                                                <div className="col-sm-4">
                                                    <InputField type="text" error={(errors.bankaddress1) ? errors.bankaddress1 : ''} name="bankaddress1" id="bankaddress1" placeholder={this.props.t('dealerDetails.kyc_details.branch_address_line1')} value={kycDealerData.address1} label={this.props.t('dealerDetails.kyc_details.branch_address_line1')} onChange={this.handletKycDealerDataTextChange.bind(this, 'address1')} />
                                                </div>
                                                <div className="col-sm-4">
                                                    <InputField type="text" name="bankaddress2" id="bankaddress2" placeholder={this.props.t('dealerDetails.kyc_details.branch_address_line2')} value={kycDealerData.address2} label={this.props.t('dealerDetails.kyc_details.branch_address_line2')} onChange={this.handletKycDealerDataTextChange.bind(this, 'address2')} />
                                                </div>
                                                <div className="col-sm-4 form-group">
                                                    <label>{this.props.t('dealerDetails.kyc_details.state')}</label>
                                                    <Select
                                                        components={{ IndicatorSeparator:() => null }}
                                                        id="state_id"
                                                        onChange={this.handleStateOptionChange()}
                                                        options={StateList}
                                                        name="state_id"
                                                        placeholder={this.props.t('dealerDetails.kyc_details.state')}
                                                        value={StateList.filter(({ id }) => id == kycDealerData.bank_state_id)}
                                                        getOptionLabel={({ name }) => name}
                                                        getOptionValue={({ id }) => id}
                                                    />
                                                    {errors.state_id &&
                                                        <div className="error show" >{errors.state_id}</div>
                                                    }
                                                </div>
                                                <div className="col-sm-4 form-group">
                                                    <label>{this.props.t('dealerDetails.kyc_details.location_of_bank')}</label>
                                                    <Select
                                                        components={{ IndicatorSeparator:() => null }}
                                                        id="city_id"
                                                        options={filteredcity}
                                                        name="city_id"
                                                        placeholder={this.props.t('dealerDetails.kyc_details.location_of_bank')}
                                                        value={filteredcity.filter(({ id }) => id === kycDealerData.bank_city_id)}
                                                        getOptionLabel={({ name }) => name}
                                                        getOptionValue={({ id }) => id}
                                                        onChange={this.handleCityOptionChange.bind(this, 'city_id')}
                                                    />
                                                    {errors.city_id &&
                                                        <div className="error show" >{errors.city_id}</div>
                                                    }
                                                </div>
                                                <div className="col-sm-4">
                                                    <InputField type="tel" error={(errors.pincode) ? errors.pincode : ''} name="pincode" id="pincode" placeholder="Pin Code" value={kycDealerData.bank_pincode} label={this.props.t('dealerDetails.kyc_details.pincode')} onChange={this.handletKycDealerDataTextChange.bind(this, 'bank_pincode')} />
                                                </div>
                                                <div className="col-sm-4 form-group rc-inline">
                                                    <div>{this.props.t('dealerDetails.kyc_details.type_of_account')}</div>
                                                    <RadioBox type="radio" name="account_type" id="saving" value="saving" label={this.props.t('dealerDetails.kyc_details.saving')} checked={(kycDealerData.bank_account_type == 'saving') ? true : false} onChange={this.handledealertype.bind(this, 'account_type')} />
                                                    <RadioBox type="radio" name="account_type" id="current" value="current" label={this.props.t('dealerDetails.kyc_details.current')} checked={(kycDealerData.bank_account_type == 'current') ? true : false} onChange={this.handledealertype.bind(this, 'account_type')} />
                                                    {errors.account_type &&
                                                        <div className="error show" >{errors.account_type}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <InputField disabled={kycDealerData.bank_id && kycDealerData.bank_id!=""?false:true} type="password" error={(errors.acno) ? errors.acno : ''} name="acno" id="acno" placeholder={this.props.t('dealerDetails.kyc_details.bank_account_number')} value={kycDealerData.bank_account_no} label={this.props.t('dealerDetails.kyc_details.bank_account_number')} onChange={this.handletKycDealerDataTextChange.bind(this, 'bank_account_no')} required={true} account_length={this.state.account_length}  RestrictOnPaste={1} RestrictOnCopy={1}/>
                                                </div>
                                                <div className="col-sm-4">
                                                    <InputField disabled={kycDealerData.bank_id && kycDealerData.bank_id!=""?false:true} type="tel" error={(errors.coacno) ? errors.coacno : ''} name="coacno" id="coacno" placeholder={this.props.t('dealerDetails.kyc_details.confirm_bank_account_number')} value={kycDealerData.confirm_bank_account_no} label={this.props.t('dealerDetails.kyc_details.confirm_bank_account_number')} onChange={this.handletKycDealerDataTextChange.bind(this, 'confirm_bank_account_no')} required={true} account_length={this.state.account_length} RestrictOnPaste={1} RestrictOnCopy={1} autocomplete="new-password"/>
                                                </div>
                                                <div className="col-sm-4">
                                                    <InputField type="text" error={(errors.micrcode) ? errors.micrcode : ''} name="micrcode" id="micrcode" placeholder={this.props.t('dealerDetails.kyc_details.micr_code_of_branch')} value={kycDealerData.bank_micr_code} label={this.props.t('dealerDetails.kyc_details.micr_code_of_branch')} onChange={this.handletKycDealerDataTextChange.bind(this, 'bank_micr_code')} />
                                                </div>
                                                <div className="col-sm-4">
                                                    <InputField type="text" error={(errors.beneficiary_name) ? errors.beneficiary_name : ''} name="beneficiary_name" id="beneficiary_name" placeholder={this.props.t('dealerDetails.kyc_details.beneficiary_name')} value={kycDealerData.beneficiary_name} label={this.props.t('dealerDetails.kyc_details.beneficiary_name')} onChange={this.handletKycDealerDataTextChange.bind(this, 'beneficiary_name')} required={true} />
                                                </div>
                                                </div>
                                                <p className="text-danger form-group f12">*{this.props.t('dealerDetails.kyc_details.kyc_doc_validation')}</p>

                                            {
                                                (kycDealerAddDoc.length) ?
                                                    kycDealerAddDoc.map((kycDealerDoc, idx) =>
                                                        <div className="row" key={idx}>
                                                            <input type="hidden" name="dc_dealer_kyc_doc" id="dc_dealer_kyc_doc" value={(kycDealerDoc.id) ? kycDealerDoc.id : ''} ></input>
                                                            <input type="hidden" name="saved_file_name" id="saved_file_name" value={(kycDealerDoc.file_name) ? kycDealerDoc.file_name : ''} ></input>
                                                            <input type="hidden" name="saved_file_url" id="saved_file_url" value={(kycDealerDoc.file_url) ? kycDealerDoc.file_url : ''} ></input>

                                                            <div className="col-sm-2">
                                                                <label>{this.props.t('dealerDetails.kyc_details.kyc_category')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                                                <Select
                                                                    components={{ IndicatorSeparator:() => null }}
                                                                    id="add_kyc_category"
                                                                    onChange={this.handleKycDocCategoryOptionChange(idx)}
                                                                    options={kycMasterlistAddKyc}
                                                                    name="add_kyc_category"
                                                                    placeholder={this.props.t('dealerDetails.kyc_details.kyc_category')}
                                                                    value={kycMasterlistAddKyc.filter(({ id }) => id === kycDealerDoc.kyc_category_id)}
                                                                    getOptionLabel={({ name }) => name}
                                                                    getOptionValue={({ id }) => id}
                                                                />

                                                                {
                                                                    (kyc_doc_errors && kyc_doc_errors[idx] && kyc_doc_errors[idx].add_kyc_category) ? <span className="error show">{kyc_doc_errors[idx].add_kyc_category}</span> : ''
                                                                }
                                                            </div>
                                                            <div className="col-sm-3 form-field add_bank_file">
                                                                <label>{this.props.t('dealerDetails.kyc_details.doc_file')}</label>
                                                                <input type="file" name="fileName" multiple className={
                                                                    (kycDealerData.file_url != '' && kycDealerData.file_url != null) ?
                                                                        'form-control hide' : 'form-control'
                                                                } onChange={this.onChangeHandler} />
                                                                {
                                                                    (kyc_upload_doc_errors && kyc_upload_doc_errors[idx] && kyc_upload_doc_errors[idx].doc_upload) ? <span className="error show">{kyc_upload_doc_errors[idx].doc_upload}</span> : ''
                                                                }
                                                            </div>

                                                            <div className="col-sm-1 pad-t25">
                                                                {
                                                                    (idx > 0) ?
                                                                        <Button type="button" btnClass="btn btn-link pull-right" onClick={this.deleteKycDocRowCategory.bind(this, idx)}><i className="ic-delete icons-normal text-secondary"></i></Button> : ''
                                                                }
                                                            </div>

                                                        </div>
                                                    ) : ''
                                            }
                                            <Button type="button" btnClass="btn btn-link" onClick={this.addKycRowCategory}> {this.props.t('dealerDetails.kyc_details.add_more_kyc')}</Button>
                                            <div className="card-footer text-center">
                                                <div><Button btnClass="btn btn-primary" title={this.props.t('dealerDetails.kyc_details.save')} type="submit" /></div>
                                            </div>
                                        </form>
                                    </div>
                                </Accordion>
                                : ''}

                            {
                                kycDealerDetail.length > 0 ?
                                    kycDealerDetail.map((kyc_dealer_data, index) =>
                                        <Accordion key={kyc_dealer_data.id} title={kyc_dealer_data.branch_name+ " - " +kyc_dealer_data.bank_account_no}>
                                            {
                                                role_access.includes("kyc_bank_status_change") ? (kyc_dealer_data.active_status == '1' || kyc_dealer_data.active_status == '0') ? <>
                                                
                                                    <label className="delete-user switch-btn btn btn-link" htmlFor={"active" + kyc_dealer_data.id} >                                                    
                                                        <input className="switch-btn" id={"active" + kyc_dealer_data.id} value="open" name={"active" + kyc_dealer_data.id} type="checkbox" onChange={this.handleChangeForFlip('showCommentMsg', index, kyc_dealer_data.id)} checked={(kyc_dealer_data.active_status == '1') ? true : false} />
                                                        {/* <input className="switch-btn" id={"active" + kyc_dealer_data.id} value="open" name={"active" + kyc_dealer_data.id} type="checkbox" onClick={() => this.handleChangeForFlip('showCommentMsg',kyc_dealer_data.id, index)} checked={(kyc_dealer_data.active_status == '1') ? true : false} /> */}
                                                        <div className="slider round"></div>
                                                        <span className="switch-label"></span>
                                                        {this.props.t('Listing.Search_Result.Active')}
                                                    </label></>
                                                    : "":''
                                            }
                                            
                                            <div className="card-heading">
                                                <h2 className="card-title">{this.props.t('dealerDetails.kyc_details.bank_detail')}</h2>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-4 form-group">
                                                        <label>{this.props.t('dealerDetails.kyc_details.bank_name')}</label>
                                                        <Select
                                                            components={{ IndicatorSeparator:() => null }}
                                                            id={"bankname" + index}
                                                            options={bankList}
                                                            isDisabled={true}
                                                            name={"bankname" + index}
                                                            placeholder={this.props.t('dealerDetails.kyc_details.bank_name')}
                                                            value={bankList.filter(({ bank_id }) => bank_id == kyc_dealer_data.bank_id)}
                                                            getOptionLabel={({ name }) => name}
                                                            getOptionValue={({ bank_id }) => bank_id}
                                                        />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <InputField type="text" disabled={true}  name={'branch_name' + index} id={"branch_name" + index} placeholder={this.props.t('dealerDetails.kyc_details.branch_name')} value={kyc_dealer_data.branch_name} label={this.props.t('dealerDetails.kyc_details.branch_name')} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <InputField type="text" disabled={true} name={"bankaddress1" + index} id={"bankaddress1" + index} placeholder={this.props.t('dealerDetails.kyc_details.branch_address_line1')} value={kyc_dealer_data.address1} label={this.props.t('dealerDetails.kyc_details.branch_address_line1')} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <InputField type="text" disabled={true} name={"bankaddress2" + index} id={"bankaddress2" + index} placeholder={this.props.t('dealerDetails.kyc_details.branch_address_line2')} value={kyc_dealer_data.address2} label={this.props.t('dealerDetails.kyc_details.branch_address_line2')} />
                                                    </div>
                                                    <div className="col-sm-4 form-group">
                                                        <label>{this.props.t('dealerDetails.kyc_details.state')}</label>
                                                        <Select
                                                            components={{ IndicatorSeparator:() => null }}
                                                            id={"state_id" + index}
                                                            onChange={this.handleStateOptionChange()}
                                                            isDisabled={true}
                                                            options={StateList}
                                                            name={"state_id" + index}
                                                            placeholder={this.props.t('dealerDetails.kyc_details.state')}
                                                            value={StateList.filter(({ id }) => id == kyc_dealer_data.bank_state_id)}
                                                            getOptionLabel={({ name }) => name}
                                                            getOptionValue={({ id }) => id}
                                                        />
                                                    </div>
                                                    <div className="col-sm-4 form-group">
                                                        <label>{this.props.t('dealerDetails.kyc_details.location_of_bank')}</label>
                                                        <Select
                                                            components={{ IndicatorSeparator:() => null }}
                                                            id={"city_id" + index}
                                                            options={filteredcity}
                                                            name={"city_id" + index}
                                                            isDisabled={true}
                                                            placeholder={this.props.t('dealerDetails.kyc_details.location_of_bank')}
                                                            value={filteredcity.filter(({ id }) => id === kyc_dealer_data.bank_city_id)}
                                                            getOptionLabel={({ name }) => name}
                                                            getOptionValue={({ id }) => id}
                                                            onChange={this.handleCityOptionChange.bind(this, 'city_id')}
                                                        />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <InputField type="tel" disabled={true} name={"pincode" + index} id={"pincode" + index} placeholder="Pin Code" value={kyc_dealer_data.bank_pincode} label={this.props.t('dealerDetails.kyc_details.pincode')} />
                                                    </div>
                                                    <div className="col-sm-4 form-group rc-inline">
                                                        <div>{this.props.t('dealerDetails.kyc_details.type_of_account')}</div>
                                                        <RadioBox type="radio" disabled={true} name={"account_type_" + index} id={"saving_"+index} value="saving" label={this.props.t('dealerDetails.kyc_details.saving')} checked={(kyc_dealer_data.bank_account_type == 'saving') ? true : false} readOnly={true} />
                                                        <RadioBox type="radio" disabled={true} name={"account_type_" + index} id={"current"+index} value="current" label={this.props.t('dealerDetails.kyc_details.current')} checked={(kyc_dealer_data.bank_account_type == 'current') ? true : false} readOnly={true} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <label>{this.props.t('dealerDetails.kyc_details.added_account_date')}:</label>
                                                        <p>{moment(kyc_dealer_data.created_date).format('YYYY-MM-DD')}</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <InputField type="password" disabled={true} name={"acno" + index} id={"acno" + index} placeholder={this.props.t('dealerDetails.kyc_details.bank_account_number')} value={kyc_dealer_data.bank_account_no} label={this.props.t('dealerDetails.kyc_details.bank_account_number')} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <InputField type="tel" disabled={true} name={"coacno" + index} id={"coacno" + index} placeholder={this.props.t('dealerDetails.kyc_details.confirm_bank_account_number')} value={kyc_dealer_data.bank_account_no} label={this.props.t('dealerDetails.kyc_details.confirm_bank_account_number')} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <InputField type="text" disabled={true} name={"micrcode" + index} id={"micrcode" + index} placeholder={this.props.t('dealerDetails.kyc_details.micr_code_of_branch')} value={kyc_dealer_data.bank_micr_code} label={this.props.t('dealerDetails.kyc_details.micr_code_of_branch')} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <InputField type="text" disabled={true} name={"beneficiary_name" + index} id={"beneficiary_name" + index} placeholder={this.props.t('dealerDetails.kyc_details.beneficiary_name')} value={kyc_dealer_data.beneficiary_name} label={this.props.t('dealerDetails.kyc_details.beneficiary_name')} />
                                                    </div>
                                                </div>
                                                {kyc_dealer_data.kyc_comment.length > 0 ?
                                                    <div className="row">
                                                        <div className="col-sm-6 form-field">
                                                            <label>{this.props.t('dealerDetails.kyc_details.comment')}:</label>                                                            
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <td colSpan="3">
                                                                            <a className="pull-right" href={() => false}
                                                                                title={this.props.t('dealerDetails.kyc_details.view_all_comment')}
                                                                                onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            this.handleChangeForViewComment('showAllCommentMsg',index ,kyc_dealer_data.kyc_comment)
                                                                                        }}
                                                                            > {this.props.t('dealerDetails.kyc_details.view_all_comment')} </a>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th style={{width: "20%"}}>{this.props.t('dealerDetails.kyc_details.action')}</th>
                                                                        <th style={{width: "60%"}}>{this.props.t('dealerDetails.kyc_details.comment')}</th>
                                                                        <th style={{width: "20%"}}>{this.props.t('dealerDetails.kyc_details.comment_date')}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{width: "20%"}}>{kyc_dealer_data.kyc_comment[kyc_dealer_data.kyc_comment.length - 1].comment_flag == "1"? this.props.t('dealerDetails.kyc_details.active') : this.props.t('dealerDetails.kyc_details.inactive')} </td>
                                                                        <td style={{width: "60%"}}>{kyc_dealer_data.kyc_comment[kyc_dealer_data.kyc_comment.length - 1].comment}</td>
                                                                        <td style={{width: "20%"}}>{moment(kyc_dealer_data.kyc_comment[kyc_dealer_data.kyc_comment.length - 1].created_date).format('YYYY-MM-DD')}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    : ''}
                                                {
                                                    (kyc_dealer_data.kyc_doc.length) ?
                                                    kyc_dealer_data.kyc_doc.map((doc, idx) =>
                                                    <div key={'kyc-dealer-data-'+idx}>
                                                            <div className="row">
                                                                <div className="col-sm-4">
                                                                    <label>{this.props.t('dealerDetails.kyc_details.kyc_category')}</label>
                                                                    <Select
                                                                        components={{ IndicatorSeparator:() => null }}
                                                                        id="add_kyc_category"
                                                                        //onChange={this.handleKycDocCategoryOptionChange(idx)}
                                                                        options={kycMasterlistAddKyc}
                                                                        isDisabled={true}
                                                                        name="add_kyc_category"
                                                                        placeholder={this.props.t('dealerDetails.kyc_details.kyc_category')}
                                                                        value={kycMasterlistAddKyc.filter(({ id }) => id === doc.kyc_category_id)}
                                                                        getOptionLabel={({ name }) => name}
                                                                        getOptionValue={({ id }) => id}
                                                                    />
                                                          
                                                                </div>
                                                                <div className="col-sm-4" >
                                                                    <label>{this.props.t('dealerDetails.kyc_details.doc_file')}</label>
                                                                    {
                                                                        (doc.file_url != '' && doc.file_url != null) ?
                                                                            <div className="document-link">
                                                                                {
                                                                                    (doc.file_url != '' && doc.file_url != null) ?
                                                                                        <a href={doc.file_url} target="_blank" rel="noreferrer" download>
                                                                                            {
                                                                                                (doc.kyc_doc_type == 'doc') ? <i className="ic-word-file icons-normal"></i> : (doc.kyc_doc_type == 'image') ? <i className="ic-image-file icons-normal"></i> : (doc.kyc_doc_type == 'pdf') ? <i className="ic-pdf-file icons-normal"></i> : ''
                                                                                            }
                                                                                            View File
                                                                                        </a>
                                                                                        : ''
                                                                                }
                                                                            </div>
                                                                        : ''
                                                                    }
                                                                </div>
                                                        </div>
                                                    </div>
                                                        ) : ''
                                                }

                                            </div>
                                        </Accordion>
                                    ) : ''}
                        </div>
                    </div>
                </div>







                <div className={(this.state.loading ? "card loading" : "card")}>
                    <form onSubmit={this.saveKycDetail} method="post" encType="multipart/form-data">
                        <div className="card-heading">
                            <div className='pull-left'>
                                <h2 className="card-title">{this.props.t('dealerDetails.kyc_details.upload_kyc_document')}</h2>
                            </div>
                           {
                                role_access.includes("kyc_share") ? 
                                <div className="pull-right">
                                    <button className="btn btn-primary" onClick={(e) => {this.handleShareDoc(e)}}>{this.props.t('dealerDetails.kyc_details.share')}</button>
                                </div> : ''
                           } 
                            <div className="clearfix"></div>
                        </div>

                        <div className="card-body">
                            <p className="text-danger form-group f12">*{this.props.t('dealerDetails.kyc_details.kyc_doc_validation')}</p>

                            {
                                (kycDealerDocData.length) ?
                                    kycDealerDocData.map((kycDealerDoc, idx) =>
                                        <div className="row" key={idx}>
                                            <input type="hidden" name="dc_dealer_kyc_doc" id="dc_dealer_kyc_doc" value={(kycDealerDoc.id)?kycDealerDoc.id:''} ></input>
                                            <input type="hidden" name="saved_file_name" id="saved_file_name" value={(kycDealerDoc.file_name)?kycDealerDoc.file_name:''} ></input>
                                            <input type="hidden" name="saved_file_url" id="saved_file_url" value={(kycDealerDoc.file_url)?kycDealerDoc.file_url:''} ></input>
                                            <div className="col-sm-2">
                                                <label>{this.props.t('dealerDetails.kyc_details.kyc_category')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                                <Select
                                                    components={{ IndicatorSeparator:() => null }}
                                                    id="kyccategory"                                                    
                                                    onChange={this.handleDocCategoryOptionChange(idx)}
                                                    options={kycMasterlist}
                                                    name="kyccategory"
                                                    placeholder={this.props.t('dealerDetails.kyc_details.kyc_category')}
                                                    value={kycMasterlist.filter(({ id }) => id === kycDealerDoc.kyc_master_id)}
                                                    getOptionLabel={({ name }) => name}
                                                    getOptionValue={({ id }) => id}
                                                    //isDisabled={(kycDealerDoc.disable_status)?kycDealerDoc.disable_status:''}
                                                />
                                                {
                                                    kycDealerDoc.file_url && !this.state.kycOriginalVerifiedStatus[idx] && <span className='f12 error show'>Unverified</span>
                                                }
                                                {
                                                    (kyc_errors && kyc_errors[idx] && kyc_errors[idx].kyccategory) ? <span className="error show">{kyc_errors[idx].kyccategory}</span> : ''
                                                }
                                            </div>

                                            <div className="col-sm-2">
                                                <label>{this.props.t('dealerDetails.kyc_details.kyc_document')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                                <Select
                                                    components={{ IndicatorSeparator:() => null }}
                                                    id="kycdoc"
                                                    onChange={this.handleKycDocChange(idx)}
                                                    options={masterlist['cat_' + kycDealerDoc.kyc_master_id]}
                                                    name="kycdoc"
                                                    placeholder={this.props.t('dealerDetails.kyc_details.kyc_document')}
                                                    value={(masterlist['cat_' + kycDealerDoc.kyc_master_id]) ? masterlist['cat_' + kycDealerDoc.kyc_master_id].filter(({ kyc_child_id }) => kyc_child_id === kycDealerDoc.kyc_child_id) : ''}
                                                    getOptionLabel={({ kyc_child_name }) => kyc_child_name}
                                                    getOptionValue={({ kyc_child_id }) => kyc_child_id}
                                                    //isDisabled={(kycDealerDoc.disable_status)?kycDealerDoc.disable_status:''}
                                                />

                                                { kycDealerDoc && kycDealerDoc.latitude && kycDealerDoc.longitude ? 
                                                    <a className='active latlonTooltip'>{this.props.t('dealerDetails.kyc_details.check_geo_tag')}
                                                        <ToolTip title={`Latitude:${kycDealerDoc.latitude} Longitude:${kycDealerDoc.longitude}`}>
                                                            <i className='ic-info-icon mrg-l5'></i>
                                                        </ToolTip>
                                                    </a> : ""
                                                }

                                                {
                                                    (kyc_child_doc_errors && kyc_child_doc_errors[idx] && kyc_child_doc_errors[idx].kycdoc) ? <span className="error show">{kyc_child_doc_errors[idx].kycdoc}</span> : ''
                                                }
                                            </div>
                                            <div className="col-sm-2">
                                            {
                                            (kycDealerDoc && kycDealerDoc.kyc_child_id && kycDealerDoc.kyc_child_id==2)?                                            
                                            <>
                                            <label>{this.props.t('dealerDetails.kyc_details.document_number')}</label>
                                            <PatternFormat 
                                                className="form-control"
                                                format="##.###.###.#-###.###"
                                                mask="_"
                                                // error={(kyc_errors && kyc_errors[idx] && kyc_errors[idx].docnumber)  ? kyc_errors[idx].docnumber : ''} 
                                                type="text" 
                                                name="docnumber" 
                                                id="docnumber" 
                                                placeholder={this.props.t('dealerDetails.kyc_details.document_number')} 
                                                value={kycDealerDoc.doc_number} 
                                                label={this.props.t('dealerDetails.kyc_details.document_number')} 
                                                onChange={this.handletkycDealerDocTextChange.bind(this, 'doc_number', idx)} 
                                                />   
                                                {/* {
                                                    (kyc_errors && kyc_errors[idx] && kyc_errors[idx].docnumber) ? <span className="error show">{kyc_errors[idx].docnumber}</span> : ''
                                                }                                              */}
                                                
                                                </>
                                                
                                                :
                                            <InputField 
                                                error={ [1,3].includes(kycDealerDoc.kyc_master_id) ? (kyc_errors && kyc_errors[idx] && kyc_errors[idx].docnumber)  ? kyc_errors[idx].docnumber : '' : ''} 
                                                type="text" 
                                                name="docnumber" 
                                                id="docnumber" 
                                                placeholder={this.props.t('dealerDetails.kyc_details.document_number')} 
                                                value={kycDealerDoc.doc_number} 
                                                label={this.props.t('dealerDetails.kyc_details.document_number')} 
                                                onChange={this.handletkycDealerDocTextChange.bind(this, 'doc_number', idx)} 
                                                />                                            
                                            }
                                            </div>
                                            <div className="col-sm-2">
                                                <InputField type="text" name="Comment" id="Comment" placeholder={this.props.t('dealerDetails.kyc_details.comment')} value={kycDealerDoc.comment} label={this.props.t('dealerDetails.kyc_details.comment')} onChange={this.handletkycDealerDocTextChange.bind(this, 'comment', idx)} />
                                            </div>
                                            <div className="col-sm-3" >
                                                <label>{this.props.t('dealerDetails.kyc_details.doc_file')}</label>
                                                {  kycDealerDoc?.kyc_doc_child_data?.max > kycDealerDoc?.kyc_doc_images?.length  || this.state.kycDealerDocData?.length > 0 ? 
                                                        <input type="file" name="fileName" multiple className={ 'form-control dealer-doc' } onChange={this.onChangeHandler} /> : ''
                                                }
                                                {
                                                    (kycDealerDoc?.kyc_doc_images?.length) ? kycDealerDoc.kyc_doc_images.map((doc_image, idx) => 
                                                                doc_image?.file_url != '' && doc_image?.file_url != null ? 
                                                                <div className="document-link">
                                                                    {
                                                                        (doc_image?.file_url != '' && doc_image?.file_url != null) ?
                                                                            <a href={doc_image?.file_url} target="_blank" rel="noreferrer" download>
                                                                                {
                                                                                    (doc_image?.kyc_doc_type == 'doc') ? <i className="ic-word-file icons-normal"></i> : (doc_image?.kyc_doc_type == 'image') ? <i className="ic-image-file icons-normal"></i> : (doc_image?.kyc_doc_type == 'pdf') ? <i className="ic-pdf-file icons-normal"></i> : ''
                                                                                }
                                                                                View File
                                                                            </a>
                                                                            : ''
                                                                    }
                                                                    <span className="delete-docs" onClick={this.removecurrentdoc.bind(this, idx,doc_image,kycDealerDoc.id)}><i className="ic-clearclose"></i></span>
                                                                </div>
                                                                : ''
                                                    ) : ''
                                                }

                                            </div>
                                            <div className="col-sm-1 share-doc-verified">
                                                {kycDealerDoc?.kyc_doc_images?.length > 0 && (
                                                    (this.state.kycOriginalVerifiedStatus[idx]) ? (
                                                        <span  className='f12 text-green show'>Verified</span>
                                                    ) : (
                                                        <label htmlFor={`is_doc_verified_${idx}`} className='gs_control gs_checkbox'>
                                                            <input
                                                                id={`is_doc_verified_${idx}`}
                                                                className=''
                                                                key={`is_doc_verified_${idx}`}
                                                                type="checkbox"
                                                                name="is_doc_verified"
                                                                value={kycDealerDoc && parseInt(kycDealerDoc.is_doc_verified) === 1 ? "1" : "0"}
                                                                checked={kycDealerDoc && parseInt(kycDealerDoc.is_doc_verified) === 1 ? true : false}
                                                                onChange={this.handleKycCheckbox.bind(this, 'is_doc_verified', idx, kycDealerDoc)}
                                                            />
                                                            {" Verify"}
                                                            <span className="gs_control__indicator"></span>
                                                            {
                                                                (kyc_errors && kyc_errors[idx] && kyc_errors[idx].is_doc_verified) ? <span className="error show">{kyc_errors[idx].is_doc_verified}</span> : ''
                                                            }
                                                        </label>
                                                    )
                                                )}
                                                {
                                                    (idx > 3) ?
                                                        <Button type="button" btnClass="btn btn-link pull-right" onClick={this.deleteKycDocRow.bind(this, idx)}><i className="ic-delete icons-normal text-secondary"></i></Button> : ''
                                                }
                                            </div>
                                        </div>
                                    ) : ''
                            }
                            <Button type="button" btnClass="btn btn-link" onClick={this.addKycRow}> {this.props.t('dealerDetails.kyc_details.add_more_kyc')}</Button>
                        </div>
                        {
                            (kycDealerDocData.length) ?
                                <div className="card-footer text-center">
                                    {
                                        role_access.includes("kyc_doc_upload_save") ? <div><Button btnClass="btn btn-primary" title={this.props.t('dealerDetails.kyc_details.save')} type="submit" /></div> : ''
                                    }
                                </div> : ''
                        }
                    </form>
                </div>
                {
                    (role == 'admin') ?
                        <div className={(this.state.loading ? "card loading" : "card")}>
                            <form onSubmit={this.saveKycCallVerificationDetail} method="post" encType="multipart/form-data">
                                <div className="card-heading">
                                    <h2 className="card-title">{this.props.t('dealerDetails.kyc_details.call_verification')}</h2>
                                </div>
                                <div className="card-body">
                                    {
                                        (kycDealerCallVerificationData.length) ?
                                            kycDealerCallVerificationData.map((kycDealerCallVerification, idx) =>
                                                <div className="row mrg-b15" key={idx} style={{ borderBottom: kycDealerCallVerificationData.length > idx + 1 ? "1px dashed #ccc" : "" }}>
                                                    <input type="hidden" name="dc_dealer_kyc_call_verification_id" id="dc_dealer_kyc_call_verification_id" value={(kycDealerCallVerification.id) ? kycDealerCallVerification.id : ''} ></input>
                                                    <div className="col-sm-4">
                                                        <InputField error={(cv_link_errors[idx + "_link"]) ? cv_link_errors[idx + "_link"] : ''} type="text" name="link" id="link" placeholder={this.props.t('dealerDetails.kyc_details.link')} value={kycDealerCallVerification.link} label={this.props.t('dealerDetails.kyc_details.link')} onChange={this.handletkycDealerCallVerificationTextChange.bind(this, 'link', idx)} />
                                                    </div>
                                                    <div className="col-sm-6 form-group">
                                                        <label>{this.props.t('dealerDetails.kyc_details.comment')}</label>
                                                        <textarea onChange={this.handletkycDealerCallVerificationTextChange.bind(this, 'comment', idx)} rows='2' className="form-control" name="comment" value={kycDealerCallVerification.comment}></textarea>
                                                        {/* <InputField type="text" name="comment" id="Comment" placeholder={this.props.t('dealerDetails.kyc_details.comment')} value={kycDealerCallVerification.comment} label={this.props.t('dealerDetails.kyc_details.comment')} onChange={this.handletkycDealerCallVerificationTextChange.bind(this, 'comment', idx)} /> */}
                                                    </div>
                                                    <div className="col-sm-1 pad-t25">
                                                        <Button type="button" btnClass="btn btn-link pull-right" onClick={this.deleteKycCallVerificationRow.bind(this, idx)}><i className="ic-delete icons-normal text-secondary"></i></Button>
                                                    </div>
                                                </div>
                                            ) : ''
                                    }
                                    <Button type="button" btnClass="btn btn-link" onClick={this.addKycCallVerificationRow}> {this.props.t('dealerDetails.kyc_details.add_more_call_verification')}</Button>
                                </div>
                                {
                                    (kycDealerCallVerificationData.length > 0) ?
                                        <div className="card-footer text-center">
                                            {
                                                role_access.includes("kyc_call_verification_save") ? <div><Button btnClass="btn btn-primary" title={this.props.t('dealerDetails.kyc_details.save')} type="submit" /></div> : ''
                                            }
                                        </div> : ''
                                }
                            </form>
                        </div>
                        : ''
                }

                { 
                        role_access.includes("kyc_survay_report_save") ?
                        <div className={this.state.loading ? 'card loading' : 'card'}>
                            <form onSubmit={this.saveSurvayReportDetail} method="post" encType="multipart/form-data">
                                <div className="card-heading">
                                <h2 className="card-title">{this.props.t('dealerDetails.kyc_details.Survay_report_details')}</h2>
                                </div>
                                <div className="card-body">
                                <div className="row">
                                

                                { ( survay_report_detail && survay_report_detail.file_url ) ? <div className="col-sm-4">
                                            <label>{this.props.t('dealerDetails.kyc_details.doc_file')}</label>
                                            <div className="document-link">
                                                {
                                                    (survay_report_detail.file_url != '' && survay_report_detail.file_url != null) ?
                                                        <a href={survay_report_detail.file_url} target="_blank" rel="noreferrer" download>
                                                            {
                                                                (survay_report_detail.doc_type == 'doc') ? <i className="ic-word-file icons-normal"></i> : (survay_report_detail.doc_type == 'image') ? <i className="ic-image-file icons-normal"></i> : (survay_report_detail.doc_type == 'pdf') ? <i className="ic-pdf-file icons-normal"></i> : ''
                                                            }
                                                            View File
                                            </a>
                                                        : ''
                                                }
                                                <span className="delete-docs" onClick={this.removecurrentsurvaydoc.bind(this)}><i className="ic-clearclose"></i></span>
                                            </div>
                                            </div> :

                                    <div className="col-sm-4 survay_report_file">
                                    <label>{this.props.t('dealerDetails.kyc_details.upload_report_document')}</label>
                                    <input type="file" name="survay_report_file" multiple className='form-control' onChange={this.survayReportFileHandler} />
                                    {survey_recommendation_errors && survey_recommendation_errors.survey_doc ? (
                                    <span className="error show">{survey_recommendation_errors.survey_doc}</span>
                                    ) : (
                                    ''
                                    )}
                                    </div> 
                                }

                                    <div className="col-sm-4">
                                    <label>{this.props.t('dealerDetails.kyc_details.comment')}</label>
                                    <textarea rows="1" className="form-control" name="comment" onChange={this.handleSurvayCommentChange.bind(this, 'comment')} value={suvay_report_comment} ></textarea>
                                    </div>

                                </div>
                                <br></br>
                                    <div className="card os-animation animated fadeInDown" data-os-animation="fadeInDown" data-os-animation-delay="0s">
                                        <div className="accrdian-bt0">
                                                <Accordion title={this.props.t('dealerDetails.kyc_details.survey_address')}>
                                                    <div className="card-heading">
                                                        <h2 className="card-title">{this.getSurveyAddressHeading(survey_report_address)}</h2>
                                                    </div>
                                                    <div className="card-body">
                                                    <div className="row">
                                                    <div className="col-sm-6">
                                                        <InputField
                                                        type="text"
                                                        name="survey_address_line1"
                                                        id={'survey_address_line1'}
                                                        placeholder={this.props.t('dealerDetails.Outlet_Details_master.current_Address_Line') + '1'}
                                                        value={survey_report_address?.survey_address_line1}
                                                        label={this.props.t('dealerDetails.Outlet_Details_master.current_Address_Line') + '1'}
                                                        onChange={this.handleSurveyOptionChange.bind( this,'survey_address_line1')}
                                                        required={true}
                                                        error={survey_report_address_errors && survey_report_address_errors.survey_address_line1}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <InputField
                                                        type="text"
                                                        id={'survey_address_line2'}
                                                        name="survey_address_line2"
                                                        placeholder={this.props.t('dealerDetails.Outlet_Details_master.current_Address_Line') + '2'}
                                                        value={survey_report_address?.survey_address_line2}
                                                        label={this.props.t('dealerDetails.Outlet_Details_master.current_Address_Line') + '2'}
                                                         onChange={this.handleSurveyOptionChange.bind(this, 'survey_address_line2')}
                                                        />
                                                    </div>
                                                    <div className="col-sm-6 form-group">
                                                        <label>
                                                        {this.props.t('dealerDetails.Outlet_Details_master.State_label')}
                                                        <span className="required" style={{ color: 'red' }}>
                                                            *
                                                        </span>
                                                        </label>
                                                        <Select
                                                        maxMenuHeight={200}
                                                        components={{ IndicatorSeparator: () => null }}
                                                        id="survey_state_id"
                                                        onChange={this.handleSurveyOptionChange.bind(this,'survey_state_id')}
                                                        options={StateList}
                                                        name="survey_state_id"
                                                        placeholder={this.props.t('dealerDetails.Outlet_Details_master.current_State_placeholder')}
                                                        value={StateList && StateList.filter(({ id }) => id == survey_report_address?.survey_state_id)}
                                                        getOptionLabel={({ name }) => name}
                                                        getOptionValue={({ id }) => id}
                                                        />
                                                        {survey_report_address_errors && survey_report_address_errors.survey_state_id ? <span className="error show">{survey_report_address_errors.survey_state_id}</span> : ''}
                                                    </div>
                                                    <div className="col-sm-6 form-group">
                                                        <label>
                                                        {this.props.t('dealerDetails.Outlet_Details_master.current_City_label')}
                                                        <span className="required" style={{ color: 'red' }}>
                                                            *
                                                        </span>
                                                        </label>
                                                        <Select
                                                        components={{ IndicatorSeparator: () => null }}
                                                        maxMenuHeight={200}
                                                        id="survey_city_id"
                                                        onChange={this.handleSurveyOptionChange.bind(this,'survey_city_id')}
                                                        options={cityAllList.filter((v) => v.state_id == survey_report_address?.survey_state_id)}
                                                        name="survey_city_id"
                                                        placeholder={this.props.t('dealerDetails.Outlet_Details_master.current_City_placeholder')}
                                                        value={cityAllList && cityAllList.filter(({ id }) => id == survey_report_address?.survey_city_id)}
                                                        getOptionLabel={({ name }) => name}
                                                        getOptionValue={({ id }) => id}
                                                        />
                                                        {survey_report_address_errors && survey_report_address_errors.survey_city_id ? <span className="error show">{survey_report_address_errors.survey_city_id}</span> : ''}
                                                    </div>
                                                    <div className="col-sm-6 form-group">
                                                        <label>
                                                        {this.props.t('dealerDetails.Outlet_Details_master.District_label')}
                                                        <span className="required" style={{ color: 'red' }}> * </span> </label>
                                                        <Select
                                                        components={{ IndicatorSeparator: () => null }}
                                                        maxMenuHeight={150}
                                                        id="survey_district_id"
                                                        onChange={this.handleSurveyOptionChange.bind(this,'survey_district_id')}
                                                        options={districtAllList.filter((v) => v.city_id == survey_report_address?.survey_city_id)}
                                                        name="survey_district_id"
                                                        placeholder={this.props.t('dealerDetails.Outlet_Details_master.District_placeholder')}
                                                        value={districtAllList && districtAllList.filter(({ id }) => id == survey_report_address?.survey_district_id)}
                                                        getOptionLabel={({ name }) => name}
                                                        getOptionValue={({ id }) => id}
                                                        />
                                                        {survey_report_address_errors && survey_report_address_errors.survey_district_id ? <span className="error show">{survey_report_address_errors.survey_district_id}</span> : ''}
                                                    </div>
                                                    <div className="col-sm-6 form-group">
                                                        <label>
                                                        {this.props.t('dealerDetails.Outlet_Details_master.Location_label')}
                                                         <span className="required" style={{ color: 'red' }}> *</span>
                                                        </label>
                                                        <Select
                                                            components={{ IndicatorSeparator: () => null }}
                                                            maxMenuHeight={150}
                                                            id="survey_locality_id"
                                                            onChange={this.handleSurveyOptionChange.bind(this,'survey_locality_id')}
                                                            options={localityAllList}
                                                            name="survey_locality_id"
                                                            placeholder={this.props.t('dealerDetails.Outlet_Details_master.current_Location_placeholder')}
                                                            value={localityAllList && localityAllList.filter(({ id }) => id == survey_report_address?.survey_locality_id)}
                                                            getOptionLabel={({ name, pin_code }) => name + (pin_code ? ' - ' + pin_code : '')}
                                                            getOptionValue={({ id }) => id}
                                                        />
                                                        {survey_report_address_errors && survey_report_address_errors.survey_locality_id ? <span className="error show">{survey_report_address_errors.survey_locality_id}</span> : ''}
                                                    </div>
                                                    </div>
                                                    </div>
                                                </Accordion>
                                        </div>
                                    </div>
                                    {/* start recommendation */}
                                    <div className="card os-animation animated fadeInDown" data-os-animation="fadeInDown" data-os-animation-delay="0s">
                                        <div className="accrdian-bt0">
                                            <Accordion title={this.props.t('dealerDetails.kyc_details.recommendation_status')}>
                                            <div className="card-body">
                                                <div className="row">
                                                <div className="col-sm-12 form-group rc-inline">
                                                    <RadioBox
                                                    type="radio"
                                                    name="recommendation_type"
                                                    id="recommended"
                                                    value="1"
                                                    label={this.props.t('dealerDetails.Outlet_Details_master.recommended')}
                                                    checked={survey_recommendation.is_recommended == '1' ? true : false}
                                                    onChange={this.handleRecommendationType.bind(this, 'recommendation_type')}
                                                    />
                                                    <RadioBox
                                                    type="radio"
                                                    name="recommendation_type"
                                                    id="not_recommended"
                                                    value="0"
                                                    label={this.props.t('dealerDetails.Outlet_Details_master.not_recommended')}
                                                    checked={survey_recommendation.is_recommended == '0' ? true : false}
                                                    onChange={this.handleRecommendationType.bind(this, 'recommendation_type')}
                                                    />
                                                </div>
                                                { survey_recommendation && survey_recommendation.is_recommended == '1'  ?
                                                 <>
                                                <div className="row">
                                                <div className="col-sm-3">
                                                    <label>
                                                    {this.props.t('dealerDetails.Outlet_Details_master.reason_for_recommendation')}
                                                    <span className="required" style={{ color: 'red' }}>
                                                        
                                                    </span>
                                                    </label>
                                                    <Select
                                                    maxMenuHeight={200}
                                                    components={{ IndicatorSeparator: () => null }}
                                                    id="reason_for_recommendation"
                                                    onChange={this.handleRecommendationStatus.bind(this, 'reason_for_recommendation')}
                                                    options={REASON_FOR_RECOMMENDATION}
                                                    name="reason_for_recommendation"
                                                    placeholder={this.props.t('dealerDetails.Outlet_Details_master.reason_for_recommendation')}
                                                    value={REASON_FOR_RECOMMENDATION && REASON_FOR_RECOMMENDATION.filter(({ id }) => id == survey_recommendation?.reason_for_recommendation)}
                                                    getOptionLabel={({ value }) => value}
                                                    getOptionValue={({ id }) => id}
                                                    />
                                                    {survey_recommendation_errors && survey_recommendation_errors.reason_for_recommendation ? (
                                                    <span className="error show">{survey_recommendation_errors.reason_for_recommendation}</span>
                                                    ) : (
                                                    ''
                                                    )}
                                                </div>
                                                { survey_recommendation && survey_recommendation.reason_for_recommendation == '2'  ?
                                                <>
                                                <div className="col-sm-3">
                                                    <InputField type="text" maxLength={9}  error={(survey_recommendation_errors.sfa_user_id) ? survey_recommendation_errors.sfa_user_id : ''} name="sfa_user_id" id="sfa_user_id" placeholder={this.props.t('dealerDetails.Outlet_Details_master.recommended_by_sfa')} value={survey_recommendation.sfa_user_id} label={this.props.t('dealerDetails.Outlet_Details_master.recommended_by_sfa')} onChange={this.handleRecommendationTextChange.bind(this, 'sfa_user_id')} />
                                                </div>
                                                <div className="col-sm-6">
                                                    <InputField type="text" readOnly={true} value={survey_recommendation.user_and_role} label={this.props.t('dealerDetails.Outlet_Details_master.user_and_role')} />
                                                </div>
                                                </> : ''}
                                                </div>
                                                { survey_recommendation && survey_recommendation.reason_for_recommendation == '3'  ?
                                                <div className="row">
                                                <div className="col-sm-12">
                                                    <label>{this.props.t('dealerDetails.Outlet_Details_master.comment')}</label>
                                                    <textarea
                                                    rows="1"
                                                    className="form-control"
                                                    name="comment"
                                                    onChange={this.handleRecommendationTextChange.bind(this, 'comment')}
                                                    value={survey_recommendation?.comment}
                                                    ></textarea>
                                                    {survey_recommendation_errors && survey_recommendation_errors.recommendation_comment ? (
                                                    <span className="error show">{survey_recommendation_errors.recommendation_comment}</span>
                                                    ) : (
                                                    ''
                                                    )}
                                                </div>
                                                </div>
                                                : ''}
                                                
                                                </>
                                                :''}

                                                </div>
                                            </div>
                                            </Accordion>
                                        </div>
                                    </div>
                                    {/* end recommendation */}
                                </div>
                                <div className="card-footer text-center">
                                <Button btnClass="btn btn-primary" title={this.props.t('dealerDetails.kyc_details.save')} type="submit" />
                                </div>
                            </form>
                        </div>  : ''
                }


                <div className="card">
                    <div className="card-footer text-center">
                        <NavLink to={this.props.prevpage} className="btn btn-default mrg-r30"> <i className="ic-arrow_back"></i> {this.props.t('dealerDetails.Outlet_Details_master.Previous')} </NavLink>
                        {REACT_APP_TENANT != "tri-id" ? <NavLink to={this.props.nextpage} className="btn btn-primary">{this.props.t('dealerDetails.Outlet_Details_master.Next')} <i className="ic-arrow_forward"></i></NavLink> : ""}
                    </div>
                </div>
                {this.state.showCommentMsg == false ? '' :
                    <ModalPopup id="add_lead" className="modal" title={this.props.t('dealerDetails.kyc_details.add_comment')} modalClose={this.closeModal}>
                        <KycComment getDealerKycDetail={this.getDealerKycDetail} modalClose={this.closeModal} kycId={this.state.kycId} currentIndex={this.state.currentIndex} active_status={this.state.active_status} basic_details={this.state.basic_details}/>
                    </ModalPopup>
                }
                {this.state.showAllCommentMsg == false ? '' :
                    <ModalPopup id="add_lead" className="modal" title={this.props.t('dealerDetails.kyc_details.all_comment')} modalClose={this.closeModalViewAllComment}>
                        <KycAllComment modalClose={this.closeModalViewAllComment} currentIndex={this.state.currentIndex} comment={[...this.state.comment]} />
                    </ModalPopup>
                }

                {this.state.shareDocPopup === false ? '' :
                    <ModalPopup id="share_doc" className="modal" title={this.props.t('dealerDetails.kyc_details.share_doc_header')} modalClose={this.closeShareDocModel}>
                        <ShareDocHistory modalClose={this.closeShareDocModel}  dealer_id_hash={this.get_dealer_id_hash} available_docs={this.state.kycDocsAvailable} />
                    </ModalPopup>
                }

                {this.state.historyPopup === false ? null : <ModalPopup id="view_history" className="modal ViewHistory data-table" title={'View History'} modalClose={this.closeHistoryModel}>
                   {basicinfo && basicinfo.id && <ViewHistory  histoy_dealer_id={basicinfo.id}/>}
               </ModalPopup>}
                
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(KycDetails));
//export default withTranslation('dealers')(BasicDetails)